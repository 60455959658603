import React, {useState, useEffect} from 'react';
import { useSelector} from "react-redux";
import Blogs from '../components/Blogs'
import { Grid, Col, Row, Wrapper, Title, Container, ContainerPadding, Text, AltTitle, ResponsiveImage, OnlyLarge } from '../theme';
import {AllImagesGallery} from '../components/ImageList'
import Members from '../components/members'
import Calendar from '../components/Calendar'
import BlockContent from '@sanity/block-content-to-react'
import SCQuery from '../components/SCQuery'
import {colors} from '../theme/config.json'
import logo from '../assets/logo_2x.png'
import MetaTags from 'react-meta-tags';
import {BlocktoPlainText} from '../services/BlockHelper' 

const Memberpage =  ()=>{
    const statedata = useSelector(state => state.data);

    return <Grid style={{width: "100%", margin: "0px", padding: "0px"}}>
        <Row center="xs">
            <Col xs={6} sm={4} md={false} >
                <ResponsiveImage src={logo} style={{padding: "40px"}}/>
            </Col>
            <Col xs={12} sm={10} md={9} lg={7}>
                <SCQuery single query={"*[_type == 'siteSettings'][0]"}>
                    {({intro})=><Text style={{fontSize: "18px"}}>
                    <MetaTags>
                        <title>{"_STEK/// PACK"}</title>
                        <meta name="description" content={BlocktoPlainText(intro)} />
                        <meta property="og:title" content={"_STEK/// PACK"} />
                        <meta property="og:image" content={logo} />
                    </MetaTags>
                        <BlockContent blocks={intro} />
                        </Text>}
                </SCQuery>
                <br/><br/>
            </Col>
            <Col xs={false} sm={false} md={3} >
                <div style={{padding: "40px"}}>
                    <ResponsiveImage src={logo} />
                </div>
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6}>
                <AltTitle>
                    Laatste updates
                </AltTitle>
                <Container>
                    <Wrapper>
                        <Blogs initialAmount={3}/>
                    </Wrapper>
                </Container>
            </Col>
            <Col xs={12} md={6}>
                <AltTitle>
                    Upcoming
                </AltTitle>
                <Container>
                    <Wrapper style={{background: colors.stek_yellow}} >
                        <ContainerPadding style={{paddingTop: "7px"}}>
                            <Calendar />
                        </ContainerPadding>
                    </Wrapper>
                </Container>
                <br/><br/>
                <AltTitle>
                    Members
                </AltTitle>
                <Container>
                    <Wrapper>
                        <Members />
                    </Wrapper>
                </Container>
            </Col>
        </Row>
        <Row>
            <br />
        </Row>
        <Row>
            <Col xs={12}>
                <AllImagesGallery statedata={statedata} self={false} />
            </Col>
        </Row>
    </Grid>
        
}

export default Memberpage;

