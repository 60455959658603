import React from 'react';
import { useSelector } from 'react-redux';
import {Title, Grid, Row, Col, Wrapper} from '../theme';
import {Blog} from '../components/Blogs';
import MetaTags from 'react-meta-tags';
import {BlocktoPlainText} from '../services/BlockHelper';

const BlogPage = ({match})=>{
    const client = useSelector(state=>state.data.client);
    const blogs = useSelector(state=>state.data.blogposts);

    if (client && match.params && match.params.id && blogs){
        console.log(match.params.id)
        let rv = <Title> Sorry, we couldn't find that post.</Title> 
        blogs.forEach(blog => {
            console.log(blog._id)
            if (blog._id === match.params.id){
                rv = <Grid>
                    <MetaTags>
                        <title>{"_STEK/// PACK / "+blog.title}</title>
                        <meta name="description" content={BlocktoPlainText(blog.content)} />
                        <meta property="og:title" content={"_STEK/// PACK / "+blog.title} />
                        <meta property="og:image" content={blog.member.avatar} />
                    </MetaTags>
                    <Row center="xs">
                        <Col xs={12}>
                            <br/><br/><br/>
                            <Wrapper style={{background:"white"}} noBottom>
                                <Blog self={false} blogdata={blog} bottom={false} />
                            </Wrapper>
                            <br/><br/><br/>
                        </Col>
                    </Row>
                </Grid>
            }
        });
        return rv;
    } else {
        return null;
    }
}

export default BlogPage;