import sanityClient from '@sanity/client'
import envi from './environment'
import {settings} from './connector_settings.json'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import imageUrlBuilder from '@sanity/image-url'
import {nanoid} from 'nanoid'

//config.js:27 You have set `useCdn` to `true` while also specifying a token. This is usually not what you want. The CDN cannot be used with an authorization token, since private data cannot be cached. See https://docs.sanity.io/help/js-client-usecdn-token for more information.

const prevFetched = {};

export const client = (dispatch)=>{

    const environment = envi();
    let _ = settings;
    const sc = sanityClient({
        projectId: _.projectId,
        dataset: _.dataset,
        token: _.token, // or leave blank to be anonymous user
        ignoreBrowserTokenWarning: true,
        useCdn: true
    })

    const builder = imageUrlBuilder(sc)

    const squareImage = (url, width)=>{
        return builder.image(url).width(width).height(width).url();
    }

    const fetch = (query, force)=>{
        return new Promise((resolve, reject)=>{
            if (prevFetched[query] && !force){
                if (environment.dev){
                    console.log("reused cached query");
                }
                resolve(prevFetched[query]);
            } else {
                sc.fetch(query)
                .then((data)=>{
                    prevFetched[query] = data;
                    resolve(data);
                }).catch(reject);
            }
        })
    }

    const fetchAllMembers = () =>{
        fetch('*[_type=="member"]{name, bio, "slug":slug.current, "avatar":avatar.asset->url, whatsapp }')
        .then((members)=>{
            dispatch({type: DATA_ACTIONS.SET_MEMBERS, members})
        })
    }

    const fetchAllBlogposts = (force)=>{
        fetch('*[_type=="blogpost"] | order(_createdAt desc) {title, _updatedAt, content, _id,  member->{name, bio, "slug":slug.current, "avatar":avatar.asset->url }, assets[]->{title, _type, url, "image":url.asset->url}, comments[]{content, _key, member->{name, bio, "slug":slug.current, "avatar":avatar.asset->url, whatsapp }}}', force)
        .then((blogposts)=>{
            dispatch({type: DATA_ACTIONS.SET_BLOGPOSTS, blogposts})
        })
    }

    const fetchAllCanvasElements = (force)=>{
        return new Promise((resolve, reject)=>{
            fetch('*[_type=="canvasElement"] | order(_createdAt desc) {_updatedAt, _id, x, y, member->{name, bio, "slug":slug.current, "avatar":avatar.asset->url, _id, whatsapp }, data->, "imageurl": data->url.asset->url}', force)
            .then((canvaselements)=>{
                dispatch({type: DATA_ACTIONS.SET_CANVAS_ELEMENTS, canvaselements})
                resolve(canvaselements);
            })
        })
    }

    const fetchAllInitialData = ()=>{
        fetchAllBlogposts();
        fetchAllCanvasElements();
        fetchAllMembers();
    }

    const getMemberRef = (member)=>{
        return {_ref: member._id, _type: "reference"}
    }

    const createBlogPost = (title, content, assets, member)=>{
        return new Promise((resolve, reject)=>{
            sc.create({
                _type: 'blogpost',
                title,
                content,
                assets,
                member: getMemberRef(member)
            }).then((blogpost)=>{
                fetchAllBlogposts(true);
                resolve(blogpost);
            }).catch((error)=>reject(error));
        })
    }

    const removeBlogPost = (post)=>{
        return new Promise((resolve, reject)=>{
            sc.delete(post._id).then(()=>{
                fetchAllBlogposts(true);
                resolve();
            }).catch((error)=>reject(error));
        })
    }

    const saveBlogPost = (post, content)=>{
        return new Promise((resolve, reject)=>{
                sc.patch(post._id).set({content}).commit()
                .then((blogpost)=>{
                    fetchAllBlogposts(true);
                    resolve(blogpost);
                });
            })
    }

    const addComment = (member, content, id)=>{
        return new Promise((resolve, reject)=>{
            sc.patch(id)
            .setIfMissing({comments: []})
            .insert('after', 'comments[-1]', [
                {_key: nanoid(), content, member: getMemberRef(member)}
            ])
            .commit()
            .then(c=>{
                fetchAllBlogposts(true);
                resolve();
            })
        })
    }

    const removeComment = (blogid, key)=>{
        return new Promise((resolve, reject)=>{
            const commentsToRemove = [`comments[_key=="${key}"]`]
            sc.patch(blogid).unset(commentsToRemove)
                .commit().then(a=>{
                    fetchAllBlogposts(true);
                    resolve();
                })  
        })
    }

    const createYoutube = (title, url, member)=>{
     return sc.create({
        _type: "youtube",
        title,
        url,
        member: getMemberRef(member)
        })
    }

    const createLink = (title, url, member)=>{
        return sc.create({
            _type: "link",
            title,
            url,
            member: getMemberRef(member)
            })
    }

    const createTitle = (title, member)=>{
        return sc.create({
            _type: "title",
            content: title,
            member: getMemberRef(member)
        })
    }

    const createText = (content, member)=>{
        return sc.create({
            _type: "txt",
            content,
            member: getMemberRef(member)
        })
    }

    const createCanvasElement = (member, x, y, params)=>{
        return new Promise((resolve, reject)=>{
            let contentMaker = null;

            switch (params.type) {
                case "title":
                    contentMaker = ()=>createTitle(params.title, member);
                    break;
                case "link":
                    contentMaker = ()=>createLink(params.title, params.url, member);
                    break;
                case "text":
                    contentMaker = ()=>createText(params.content, member);
                    break;
                case "youtube":
                    contentMaker = ()=>createYoutube(params.title, params.url, member);
                    break;
                case "image":
                    contentMaker = ()=>new Promise((resolve, reject)=>{
                        uploadImage(params.title, params.content)
                        .then((document)=>{
                            createImage(params.title, document._id, member)
                            .then(data=>resolve(data));
                        })
                    })
            }

            if (contentMaker){
                contentMaker().then((content)=>{
                    sc.create({
                        _type: "canvasElement",
                        x, y,
                        data: {_ref: content._id, _type: "reference"},
                        member: getMemberRef(member)
                    }).then(data=>{
                        fetchAllCanvasElements(true)
                        .then(x=>resolve(data))})
                })
            } else {
                reject()
            }
        })
    }

    const removeCanvasElement = (element)=>{
        let dataid = element.data._id;
        return new Promise((resolve, reject)=>{
            sc.delete(element._id)
            .then(x=>{
                sc.delete(dataid)
                .then(x=>{
                    fetchAllCanvasElements(true)
                    .then(x=>resolve())
                })
            })
        })
    }

    const refreshMember = (member)=>{
        sc.fetch('*[_id == "'+member._id+'"][0]{_id, name, "avatar":avatar.asset->url, bio, whatsapp, "slug":slug.current}')
        .then((data)=>{
            dispatch({type: DATA_ACTIONS.SET_MEMBER, member: data})
        })
    }

    const makeImageReference = (_ref)=>{
        return  {
            _type: 'image',
            asset : {
                _type: "reference",
                _ref
            }
        }
    }

    const createImage = (title, _ref, member)=>{
        return sc.create({
            _type: "img",
            title,
            url: makeImageReference(_ref),
            member: getMemberRef(member)
        })
    }

    const uploadImage = (filename, blob)=>{
        return sc.assets.upload('image', blob, {contentType: 'image/png', filename})
    }

    const updateBio = (bio, avatar, whatsapp, member)=>{
        const newData = {}

        if (bio){
            newData.bio = bio;
        }

        if (whatsapp){
            newData.whatsapp = whatsapp;
        }

        if (avatar){
            return new Promise((resolve, reject)=>{
                uploadImage(member.name+'-avatar.jpg', avatar)
                .then((document)=>{
                    newData.avatar = makeImageReference(document._id);
                    sc.patch(member._id).set(newData).commit()
                    .then((updatedmember)=>resolve(updatedmember));
                })
            })
        } else {
            return sc.patch(member._id).set(newData).commit()
        }
    }

    if (environment.dev){
        environment.printstatus()
    } 

    return {
        fetch,
        raw: sc,
        environment,
        createBlogPost,
        createLink,
        createYoutube,
        uploadImage,
        createImage,
        updateBio,
        refreshMember,
        squareImage,
        fetchAllBlogposts,
        removeBlogPost,
        saveBlogPost,
        createCanvasElement,
        fetchAllCanvasElements,
        removeCanvasElement,
        fetchAllMembers,
        fetchAllInitialData,
        addComment,
        removeComment
    }
}

export const FETCH_STATES = Object.freeze({
    IDLE: Symbol("fetch/idle"),
    BUSY: Symbol("fetch/busy"),
    ERROR: Symbol("fetch/error"),
});

export default client;

