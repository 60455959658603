import React from 'react';
import { FaImage, FaLink } from 'react-icons/fa';
import Moment from 'react-moment';
import { useSelector, useDispatch} from "react-redux";
import styled from 'styled-components';

import { Container, ContainerPadding, SubTitle, Title, Wrapper } from '../theme';

import '../assets/css/canvas.css';

const Card = styled.div`

`;

const CardTop = styled.div`

`;

const CardContent = styled.div`
    border-bottom: 1px solid black;
    border-top: 1px solid black;
`;

const CardImage = styled.img`
    padding:15px;
    width: 100%;
    height: auto;
`;
const CardFooter = styled.div`
    display: flex;
    height: 30px;
    justify-content: space-between;
    align-items: center;
`

const FooterAvater = styled.img`
    border-radius: 50%;
    height: 25px;
`

//Don't know best way, I would do this with a base-stylesheet
const FooterTime = styled.h6`
    margin: 0;
    font-style: italic;
    font-weight: 300;
`;

const MapElements = ({onRender}) => {
    const statedata = useSelector(state  => state.data);

    const getElement = (el) => {
        //console.log(el)
        
        switch (el.data._type) {
            case 'img':
                //console.log('img', el);
                return (
                <ContainerPadding data-x={el.x} data-y={el.y}>
                    <SubTitle><FaImage/> {el.data.title}</SubTitle>
                    <CardContent>
                        <CardImage src={el.imageurl}/>
                    </CardContent>
                    {getElementFooter(el.member, el._updatedAt)}
                </ContainerPadding>
                )
            case 'link':
                return (
                <ContainerPadding data-x={el.x} data-y={el.y}>
                    <SubTitle><FaLink/> Link: {el.data.title}</SubTitle>
                    <CardContent>
                        URL: {el.data.url}
                    </CardContent>
                    {getElementFooter(el.member, el._updatedAt)}
                </ContainerPadding>
                )
            case 'txt':
                //console.log('TXT');
                break;
            case 'title':
                //console.log('TITLE');
                return (
                <ContainerPadding data-x={el.x} data-y={el.y}>
                    <Title>{el.data.content}</Title>
                    {getElementFooter(el.member, el._updatedAt)}
                </ContainerPadding>
                )
                
            default:
                return null;
        }
    }

    const getElementFooter = (member, date) => {
        return <CardFooter>
            <a href={member.slug} target="_blank">
                <FooterAvater src={member.avatar} alt={member.name}/>
                {member.name}
            </a>
            <FooterTime>
                Last update: 
                <Moment date={date} format={"YYYY-MM-DD HH:mm"}/>
            </FooterTime>
        </CardFooter>
    }

    const renderElements = statedata.canvaselements.map(el => {
        return (
            <Wrapper className="canvas-element" data-id={el._id} data-x={el.x} data-y={el.y} >
                    {getElement(el)}
            </Wrapper>
        )
    })

    onRender(true);

    return renderElements;
}

export default MapElements;