import React, {useState, useEffect} from 'react'
import TextEditor from '../components/TextEditor'
import Expand from 'react-expand-animated';
import Button from '../components/Button';
import { useSelector} from "react-redux";
import {Wrapper, Section, Col, Row, Container, ContainerPadding, Text, Line} from "../theme";
import {SendIcon, CloseIcon, NextIcon} from '../theme/icons'
import {useIsSmall} from '../services/size'
import toast from '../services/toast';
import LineInput from '../components/LineInput'
import BlockContent from '@sanity/block-content-to-react'
import {nanoid} from 'nanoid';
import ImageDrop from '../components/ImageDrop'

const ResourceAdder = ({type, value, setValue, title, setTitle})=>{
    const [open, setOpen] = useState(false);

    const getContent = ()=>{
        switch (type) {
            case 'youtube':
            case 'link':
                return <Section>
                    <LineInput title={"title"} value={title} setValue={setTitle}   NoBottom />
                    <LineInput title={type} value={value} setValue={setValue} />
                </Section>
            default:
                return <ImageDrop setTitle={setTitle} setValue={setValue} />
        }
    }

    return <Section>
            <Button text={"add "+type} onClick={()=>{setOpen(!open)}} />
            <Expand open={open}>
                <Wrapper noTop>
                    <ContainerPadding>
                        {getContent()}
                    </ContainerPadding>
                </Wrapper>
            </Expand>
    </Section>
}

const BlogPostMaker = ()=>{

    const STATES = {
        INIT: "state/init",
        ADD_RESOURCE: 'state/add',
        ADD_MORE_RESOURCES: 'state/addMore',
        FINISHED: 'state/finished'
    };

    const [open, setOpen] = useState(false);
    const [state, setState] = useState(STATES.INIT);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState(null);
    const [imageTitle, setImageTitle] = useState('');
    const [imageList, setImageList] = useState(null);
    const [linkTitle, setLinkTitle] = useState('');
    const [linkUrl, setLinkUrl] = useState('');
    const [youtubeTitle, setYoutubeTitle] = useState('');
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [savedAssets, setSavedAssets] = useState({images: [],links: [],youtubes: []});
    const [uploading, setUploading] = useState(false);

    const small = useIsSmall()
    const data = useSelector(state => state.data);

    const clearResourceGatherers = ()=>{
        setImageTitle('');
        setImageList(null);
        setLinkTitle('');
        setLinkUrl('');
        setYoutubeTitle('');
        setYoutubeUrl('');
    }

    const clearSavedAssets = ()=>{
        setSavedAssets({images: [],links: [],youtubes: []});
    }

    //useEffect(() => {});

    const onSend = ()=>{
        switch (state) {
            case STATES.INIT:
                if (title !== '' && content !== null && content.length > 0 && content[0].children.length > 0 && content[0].children[0].text != '' ){
                    setState(STATES.ADD_RESOURCE);
                } else {
                    toast("Please enter a title and add content")
                }
                console.log(content)
                break;
            case STATES.ADD_RESOURCE:

                let hasValidResource = false;
                let block = false;

                const checkTuple = (title, value, errormsg, container, customCheckContent)=>{
                    customCheckContent = customCheckContent || ((content)=>true);
                    if (title || value){
                        if (title && value && customCheckContent(value)){
                            hasValidResource = true;
                            savedAssets[container].push({title, value})
                            setSavedAssets(savedAssets);
                        } else {
                            block = true;
                            if (errormsg){
                                toast(errormsg)
                            }
                        }    
                    }
                }

                checkTuple(imageTitle, imageList, null, "images");
                checkTuple(linkTitle, linkUrl, 'Please provide a valid link and url', "links");
                checkTuple(youtubeTitle, youtubeUrl, 'Please provide a valid title and youtube url', "youtubes", (content)=>(content.indexOf('youtu') !== -1));

                if (hasValidResource && !block){
                    setState(STATES.ADD_MORE_RESOURCES);
                }else if (!block){
                    setState(STATES.FINISHED);
                }
                break;
            case STATES.ADD_MORE_RESOURCES:
                setState(STATES.FINISHED);
                break;   
            case STATES.FINISHED:
                upload();
                break;   
        }
    }

    const upload = ()=>{

        ['images', 'links', 'youtubes'].forEach(selector => {
            savedAssets[selector] = [...new Set(savedAssets[selector])];
        });

        savedAssets.links = savedAssets.links.map((link)=>{
            if (link.value.indexOf("http://") == -1 && link.value.indexOf("https://") == -1){
                link.value = 'http://'+link.value;
            }
            return {title: link.title, value: link.value}
        })
        
        let resourceList = [];

        savedAssets.links.forEach((link)=>{
            resourceList.push(data.client.createLink(link.title, link.value, data.member));
        })

        savedAssets.youtubes.forEach((youtube)=>{
            resourceList.push(data.client.createYoutube(youtube.title, youtube.value, data.member));    
        })

        const finish = (msg)=>{
            toast(msg)
            setState(STATES.INIT);
            clearSavedAssets();
            clearResourceGatherers();
            setTitle('');
            setOpen(false);
        }

        const exec = ()=>{

            Promise.all(resourceList)
            .then(results=>{
                let assets = results.map((resource)=>{
                    return { _ref: resource._id, _type: "reference", _key: nanoid()}
                })

                data.client.createBlogPost(title, content, assets, data.member)
                .then(res=>{
                    finish("new blogpost made");
                    setUploading(false);
                }).catch(err =>{
                    finish("Something went wrong");
                    setUploading(false);
                    finish();
                })
            })
        }

        setUploading(true);
        if (savedAssets.images.length > 0){
            Promise.all(savedAssets.images.map((image)=>data.client.uploadImage(image.title, image.value)))
            .then(documents => {
                documents.forEach((document)=>{
                    resourceList.push(data.client.createImage(imageList.title, document._id, data.member))
                })
                exec();
            })
            .catch(error => {
                console.error('Upload failed:', error.message)
                toast("Something went wrong while uploading")
                setUploading(false);
            })
        } else {
            exec();
        }
    }

    const getAssets = ()=>{
        let rv = [];

        const updateSavedAssets = ()=>{
            setSavedAssets({images: savedAssets.images,links: savedAssets.links,youtubes: savedAssets.youtubes});
        }

        const addAsset = (selector, type)=>{
            savedAssets[selector].forEach((asset, index)=>{
                rv.push({
                    type,
                    title: asset.title,
                    action: ()=>{
                        savedAssets[selector].splice(index, 1);
                        updateSavedAssets();
                    }
                })
            });
        }

        addAsset('links', 'link');
        addAsset('youtubes', 'youtube');
        addAsset('images', 'image');

        let assets = rv.map((asset, index)=>(
            <Wrapper noBottom={index!==(rv.length-1)}>
                <ContainerPadding size={"10px"} >
                    <span>
                        <CloseIcon style={{  transform: "translateY(3px)"}} 
                            onClick={()=>{
                                asset.action();
                            }} /> 
                        <Text>{asset.title} <i>({asset.type})</i></Text> 
                    </span>
                </ContainerPadding>
            </Wrapper>
        ));

        if (rv.length === 0){
            return null;
        } else {
            return (<Section>
                    <br />
                    <Line />
                    <br />
                    {assets}
                </Section>)
        }
    }

    const getMainContent = ()=>{

        if (state == STATES.INIT){
            return <Section>
                        <LineInput title="TITLE" value={title} setValue={setTitle} />
                        <Wrapper noTop>
                            <TextEditor value={content} setValue={setContent} />
                        </Wrapper>
                </Section>
        } else if (state == STATES.ADD_RESOURCE){
            return <Wrapper>
                        <Container>
                            <br/>
                            <ResourceAdder type="image" value={imageList} setValue={setImageList} title={imageTitle} setTitle={setImageTitle} />
                            <br/>
                            <ResourceAdder type="link" value={linkUrl} setValue={setLinkUrl} title={linkTitle} setTitle={setLinkTitle}/>
                            <br/>
                            <ResourceAdder type="youtube" value={youtubeUrl} setValue={setYoutubeUrl} title={youtubeTitle} setTitle={setYoutubeTitle}/>
                            <br/>
                            {getAssets()}
                        </Container>
                    </Wrapper>
        } else if (state == STATES.ADD_MORE_RESOURCES) {
            return <Wrapper>
                <Container>
                    <Button text="add more resources" onClick={()=>{clearResourceGatherers(); setState(STATES.ADD_RESOURCE)}} />
                    {getAssets()}
                </Container>
            </Wrapper>
        } else if (state == STATES.FINISHED) {
            return <Wrapper><Container><BlockContent blocks={content} /> {getAssets()} </Container></Wrapper>
        }
    }

    return <Container>
                <Row>
                <Col xs={12}>
                    <Button altfont={true} text={`Make new blogpost ${(title && title!="")?" / "+title:''}  `}  onClick={()=>{setOpen(!open)}} options={{bold:true, noBottom:open}}/>
                </Col>
                <Col xs={12}>
                    <Expand open={open} >
                        <Row>
                            <Col xs={12}>
                                {getMainContent()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={6}>
                                {uploading?
                                    <Button text="uploading" onClick={()=>{}} options={{noTop:true, noBottom: small}} /> :
                                    <Button component={(state === STATES.FINISHED)? SendIcon : NextIcon} onClick={onSend} options={{noTop:true, noBottom: small}} />
                                }
                            </Col>
                            <Col xs={12} sm={6}>
                                <Button component={CloseIcon} 
                                onClick={()=>{
                                    setState(STATES.INIT);
                                    setOpen(false)}} options={{noTop: !small}}/>        
                            </Col>
                        </Row>
                    </Expand>
                </Col>
            </Row>
        </Container>
}

export default BlogPostMaker;