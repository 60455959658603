import React, {useState, useEffect} from 'react';
import { useSelector} from "react-redux";
import {Title, SubTitle, ContainerPadding, Container, Section, Line, Text, InlineText, CircleImage, Row, Col, ContainerSides, Wrapper, defaultWrap} from '../theme'
import BlockContent from '@sanity/block-content-to-react'
import Button from '../components/Button'
import LinkList from '../components/LinkList'
import YoutubeList from '../components/YoutubeList'
import ImageList from '../components/ImageList'
import Moment from 'react-moment';
import {MoreIcon} from '../theme/icons';
import styled from 'styled-components';
import {arraysEqual} from '../services/helperFunctions'
import TextEditor from '../components/TextEditor'
import toast from '../services/toast';
import {colors} from '../theme/config.json';
import TextareaAutosize from 'react-textarea-autosize';
import ContextualMenu from './ContextualMenu';
import {htmlToBlock} from '../services/BlockHelper';
import { Link } from 'react-router-dom';


// const MenuContainer = styled.div`
//     font-size: 30px;
//     color: ${colors.stek_blue}
// `

// const Menu = ({onEdit, onRemove, onSave, onCancel})=>{
//     const [open, setOpen] = useState(false);

//     if (onSave){
//         return <Section >
//         <Button text="save" onClick={()=>{onSave()}} options={{noBottom: true}} />
//         <Button text="cancel" onClick={()=>{onCancel(); setOpen(false) }} />
//     </Section>
//     }else if (open){
//         return <Section >
//             <Button text="edit" onClick={()=>{onEdit()}} options={{noBottom: true}} />
//             <Button text="remove" onClick={()=>{onRemove()}} options={{noBottom: true}} />
//             <Button text="close" onClick={()=>{setOpen(!open)}} />
//         </Section>
//     } else {
//         return <MenuContainer onClick={()=>{setOpen(!open)}}>
//                     <MoreIcon  /> 
//                 </MenuContainer> 
//     }    
// }

export const Blog = ({self, blogdata, bottom})=>{
    const client = useSelector(state=>state.data.client);
    const loggedIn = useSelector(state=>state.data.member);
    const [edit, setEdit] = useState(null);
    const [busy, setBusy] = useState(false);
    const [newContent, setNewContent] = useState(null);
    const [comment, setComment] = useState('...');
    const [member, title, id, content, assets,updatedAt] = [blogdata.member, blogdata.title, blogdata._id, blogdata.content, blogdata.assets, blogdata._updatedAt];

    const removePost = ()=>{
        setBusy(true);
        client.removeBlogPost(blogdata).then(()=>{
            setBusy(false);
            toast("Blogpost removed", {hideProgressBar: true});
        })
    }

    const savePost = ()=>{
        setBusy(true);
        client.saveBlogPost(blogdata, newContent)
        .then(()=>{
            setEdit(null);
            setBusy(false);
            toast("Blogpost saved", {hideProgressBar: true});
        })
    }

    const publishComment = ()=>{
        client.addComment(loggedIn, htmlToBlock(comment), id)
        .then(a=>{
            setComment('...');
            toast('Comment posted');
        })
    }

    const removeComment = (comment)=>{
        client.removeComment(id, comment._key);
    }

    const getCommentMenu = (comment)=>{
        return {
            "verwijder" : ()=>{removeComment(comment)} 
        }
    }

    const share = ()=>{
        return {
            copy: true,
            value: window.location.origin+"/blog/"+id
        }
    }

    const getMenu = ()=>{
        const actions = {};

        if (self){
            if (edit){
                actions.save = savePost;
                actions.cancel = ()=>{setEdit(null)};
            } else {
                actions.edit = ()=>{setEdit(true)};
                actions.share = share();
                actions.remove = removePost;
            }
        } else {
            actions.share = share();
        }

        return actions;
    }

    return <React.Fragment>
            <ContainerPadding noBottom={true}>
            <ContextualMenu actions={getMenu()} transform="translateY(10px)" />
            <Row>
                <Col xs={3} sm={2}>
                    <Link to={`/member/${member.slug}`} style={{textDecoration: "none"}}>
                        <CircleImage src={client.squareImage(member.avatar, 400)}/>
                    </Link>
                </Col>
                <Col xs={8} xsOffset={1} smOffset={0} sm={10}>
                    <ContainerSides>
                        <Title align="left" style={{margin: "0px"}}>
                            {title}
                        </Title>
                        <SubTitle align="left" style={{margin: "0px"}}>
                            by {member.name}
                        </SubTitle>
                    </ContainerSides>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {edit?
                    <Section>
                        <TextEditor  value={newContent} setValue={setNewContent} blocks={content}/> 
                    </Section>
                    :
                    <InlineText>
                    <BlockContent blocks={content} />
                    </InlineText>
                    } 
                    <LinkList links={assets.filter((asset)=>asset._type == "link")} />
                    <YoutubeList links={assets.filter((asset)=>asset._type == "youtube")} />
                    <ImageList images={assets.filter((asset)=>asset._type == "img")} />
                </Col>
            </Row>
        </ContainerPadding> 
        <Row>
            <Col xs={12}>
                <div style={{width: "100% !important", textAlign: "center"}}>
                    <InlineText>
                    <Moment format="DD/MM/YYYY - HH:MM" >
                        {updatedAt}
                    </Moment>
                    </InlineText>
                </div>
                <br/>
                {loggedIn ?
                <React.Fragment>
                <Line />
                <br/> 
                 <Row>  
                     {blogdata && blogdata.comments?
                        blogdata.comments.map((comment, index)=>{
                            console.log(comment, loggedIn)
                            return (
                                <React.Fragment key={index}>
                                    {(comment.member.slug === loggedIn.slug)? <Col xs={12}><ContextualMenu actions={getCommentMenu(comment)} transform="translateY(10px)" /></Col> :null}
                                    <Col xs={12}>
                                        <Section style={{margin: "10px", width: "50px", float: "left"}}>
                                            <Link to={`/member/${comment.member.slug}`} style={{textDecoration: "none"}}>
                                                <CircleImage src={client.squareImage(comment.member.avatar, 50)}/>
                                            </Link>
                                        </Section>
                                        <div style={{marginLeft: "20px", marginRight: "30px", float : "left"}}>
                                            <InlineText>
                                                <BlockContent blocks={comment.content} />
                                            </InlineText>
                                        </div>

                                    </Col>
                                    
                                    
                                </React.Fragment>

                            )
                        }) : null}
                        <Col xs={12}>
                            <Section style={{margin: "10px", width: "50px", float: "left"}}>
                                <CircleImage src={client.squareImage(loggedIn.avatar, 50)}/>
                            </Section>
                            <div style={{marginLeft: "100px", marginRight: "100px"}}>
                            <InlineText>
                                <TextareaAutosize value={comment} onChange = {e => setComment(e.target.value)} style={{resize: "vertical", width: "100%", minRows: "3", rows: "3", overflow: "hidden", border: defaultWrap, color: colors.stek_blue}} />
                            </InlineText>
                            <div style={{width: "100%"}}>
                                <Button text="plaats commentaar" onClick={publishComment} />
                            </div>
                            </div>
                        </Col>
                </Row>
                
                <br/>
                <Line/>

                </React.Fragment> : null }

                {bottom}
            </Col>
        </Row>
    </React.Fragment>
}


const Blogs = ({initialAmount, self, other})=>{
    const data = useSelector(state=>state.data);
    const [amount, setAmount] = useState(initialAmount)
    const [blogdata, setBlogdata] = useState(null);
    const [originalData, setOriginalData] = useState(null);

    useEffect(() => {
        if (blogdata == null && data.blogposts){
            if (self && data.member){
                let filtered = data.blogposts.filter((blogpost)=>blogpost.member.name == data.member.name);
                setBlogdata(filtered);
                setOriginalData(data.blogposts);
                setAmount(Math.min(initialAmount, filtered.length))
            } else if (other){
                let filtered = data.blogposts.filter((blogpost)=>blogpost.member.name == other.name);
                setBlogdata(filtered);
                setOriginalData(data.blogposts);
                setAmount(Math.min(initialAmount, filtered.length))
            } else {
                setBlogdata(data.blogposts);
                setOriginalData(data.blogposts);
                setAmount(Math.min(initialAmount, data.blogposts.length))
            }
        } else {
            if (!arraysEqual(originalData, data.blogposts)){
                setBlogdata(null);
            }
        }

        if (blogdata && amount > blogdata.length){
            setAmount(blogdata.length)
        }
    });

    const getBottom = (index)=>{
        if (index < (initialAmount?amount-1:blogdata.length-1)){
            return (
                <Section> 
                    <br/><Line /><br/>
                </Section> 
            )
        } else if (amount == 1 && blogdata.length == 1 ){
            return <br/>;
        } else if (amount == 1 && blogdata.length > 1 ){
            return <Section>
            <br/>
            <Button text="more" onClick={()=>{setAmount(amount+initialAmount)}} options={{noBottom:true, noSides:true}} />
            </Section>
        } else if (amount < blogdata.length){
            return <Section>
                <br/>
                <Button text="more" onClick={()=>{setAmount(amount+initialAmount)}} options={{noBottom:true, noSides:true}} />
                <Button text="less" onClick={()=>{setAmount(Math.max(amount-initialAmount, 1))}} options={{noBottom:true, noSides:true}} />
                </Section>
        } else {
            return <Section>
                <br/>
                <Button text="less" onClick={()=>{setAmount(Math.max(amount-initialAmount, 1))}} options={{noBottom:true, noSides:true}} />
            </Section>;
        }
    }

    if (data.blogposts && data.blogposts.length == 0){
        return <Text>
            <Container>
                Geen blogsposts gevonden
            </Container>
        </Text>
    } else if (blogdata){
        const rendered = blogdata.filter((blog, index)=>index<(initialAmount?amount:blogdata.length)).map((blog, index)=>{

                return (
                    <Blog   key={index+blog.title} 
                            bottom={getBottom(index)}
                            self={self}
                            blogdata={blog}
                             />
                )
            }
        )
        return rendered   
    } else {
        return null;
    }
}

export default Blogs;