import React, {useState, useCallback} from 'react'
import {useDropzone} from 'react-dropzone'
import {Section, Text} from "../theme";
import Button from '../components/Button';

const ImageDrop = ({setTitle, setValue, text})=>{
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                setTitle(file.name);
                setValue(reader.result);
            }
            reader.readAsArrayBuffer(file)
            setFiles([acceptedFiles[0]]);
        })
        }, [])

        const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})
        const [files, setFiles] = useState(null);
        text = text || "Drag 'n' drop the image here, or click to select image";

        const getImageContent = ()=>{
            if (files === null){
                return <div {...getRootProps()}>
                        <input {...getInputProps()} />
                            {
                                isDragActive ?
                                <p>Drop the image here ...</p> :
                                <p>{text}</p>
                            }
                        </div>
            } else {
                return <Section>
                            {files.map((file)=>{return<Section> ∆ <Text>{file.name}</Text></Section>})}
                            <br/>
                            <Button text='remove' onClick={()=>{setFiles(null)}} />
                </Section>
            }
        }

        return getImageContent()
}

export default ImageDrop;