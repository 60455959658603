import {FiSend, FiEdit} from 'react-icons/fi'
import {IoMdClose, IoMdMore} from 'react-icons/io'
import {MdNavigateNext, MdNavigateBefore, MdAdd} from 'react-icons/md'
import {AiOutlineLeft, AiOutlineRight, AiOutlineUp, AiOutlineDown} from 'react-icons/ai'
import {FaWhatsapp} from 'react-icons/fa'
import {GiHamburgerMenu} from 'react-icons/gi'
import styled from 'styled-components';


export const SendIcon = FiSend;
export const CloseIcon = IoMdClose;
export const NextIcon = MdNavigateNext;
export const PrevIcon = MdNavigateBefore;
export const EditIcon = FiEdit;
export const MoreIcon = IoMdMore;
export const LeftIcon = AiOutlineLeft;
export const RightIcon = AiOutlineRight;
export const UpIcon = AiOutlineUp;
export const DownIcon = AiOutlineDown;
export const AddIcon = MdAdd;
export const WhatsappIcon = FaWhatsapp;
export const MenuIcon = GiHamburgerMenu;

export const IconSpan = styled.span`
    font-size: 30px;
`

export const containerWidth = "50";

export const IconContainer = styled.div`
    width: ${containerWidth}px;
    height: 40px;
    font-size: 30px;
    text-align: center;
    padding-top: 10px;
`