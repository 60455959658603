import React, { Component } from 'react';
import { useSelector} from "react-redux";
import { ClickIcon, Row, ListItem, Title, Text, InlineText, CircleImage, Section, defaultWrap, OnlyLarge} from '../theme';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import back from '../assets/icons/back-sketch.svg';
import close from '../assets/icons/close-sketch.svg';
import menu from '../assets/icons/menu-sketch.svg';
import Memberlogin from './Memberlogin'
import {colors} from '../theme/config.json'
import {MenuIcon} from '../theme/icons'

export const NavContainer = styled.div`
  margin-left: 4px;
`

export const NavTitle = styled(Title)`
  font-size: 20px;
  margin-left: 30px;
`;

export const NavBar = styled.div`
  align-items: center;
  display: flex;
  background: ${colors.stek_yellow};
  border-bottom: ${defaultWrap};
  border-top: ${defaultWrap};
  justify-content: flex-start;
  height: 40px;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 99;
`;

export const MenuPanel = styled.div`
  transition: transform 400ms cubic-bezier(0.215, 0.61, 0.355, 1);
  background: white;
  border: ${defaultWrap};
  background: ${colors.stek_yellow};
  border-top: 0px;
  padding: 4px;
  position: fixed;
  left: 38px;
  top: 30px;
  width: 280px;
  transform: ${props => props.open ? 'translateY(18px)' : 'translateY(-300px)'};
  z-index: 98;
`;

export const MenuRow = styled(Row) `
  padding: 2px;
  margin: 4px;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: ${defaultWrap};
  display: flex;
  justify-content: space-between;
`

export const MenuRowLineless = styled(MenuRow) `
  border-bottom: 0px;
`

export const MenuItem = styled(ListItem)`
  display: flex;
  cursor: pointer;
  textDecoration: none;
  color: ${colors.stek_blue}
`

let NameDisplay = ()=>{
    const member = useSelector(state => state.data.member);
    const client = useSelector(state => state.data.client);

    if (member){
        return <NavLink to="/member" style={{position: "absolute", right: "20px"}}>
                    <p style={{float: "left", transform: "translate(0, -5px)", textAlign: "center"}}>
                        <OnlyLarge>
                            <InlineText color={colors.stek_blue}>{member.name}</InlineText> &nbsp;
                        </OnlyLarge>
                        <CircleImage noWrap src={client.squareImage(member.avatar, 25)} width="25px" style={{transform: "translateY(8px)"}} />
                    </p>
                    
                </NavLink>
    } else {
        return null;
    }
}

const YourPage = ()=>{
    const member = useSelector(state => state.data.member);

    if (member){
        return <NavLink style={{textDecoration: "none"}} to='/member'>
                    <MenuItem>your page</MenuItem>
                </NavLink>
    } else {
        return null;
    }
}

class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleNav: false,
            showBack: false,
            path: null,
        }
        this.onBack = this.onBack.bind(this);
    }

    componentWillMount() {
        if (this.props.history) {
            this.setState({ path: this.props.history.location.path });
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            this.setState({ toggleNav: false });
        }
    }

    onBack() {
        this.props.history.goBack();
    }
    onToggle() {
        this.setState({
            toggleNav: !this.state.toggleNav
        });
    }

    render() {
        let menuToggle = menu;
        if (this.state.toggleNav) {
            menuToggle = close;
        }
        return (
            <span>
                <NavBar>
                <NavContainer>
                    <Section >
                        <MenuIcon onClick={() => this.onToggle()} style={{fontSize: "30px", transform: "translateY(2px)", color: colors.stek_blue}} />
                    </Section>
                    {this.state.showBack ? <ClickIcon src={back} onClick={() => this.onBack()} /> : null}
                    </NavContainer>
                    <NavTitle>
                    <NavLink to='/' style={{textDecoration: "none", color: colors.stek_blue}}>_STEK/// PACK</NavLink>
                    </NavTitle>
                    <NameDisplay />
                </NavBar>
                <MenuPanel open={this.state.toggleNav}>
                    <MenuRow>
                        <NavLink style={{textDecoration: "none"}} to='/'>
                            <MenuItem>Home</MenuItem>
                        </NavLink>
                    </MenuRow>
                    <MenuRow>
                        <Memberlogin />
                        <YourPage />
                    </MenuRow>
                    <MenuRowLineless>
                        <InlineText><b>Help & Info </b></InlineText>
                        <a href='mailto:stek@seads.network' style={{textDecoration: "none"}}>
                            <InlineText>
                                stek@seads.network
                            </InlineText>
                        </a>
                    </MenuRowLineless>
                </MenuPanel>
                <br/><br/>
            </span>
        )
    }
}

export default withRouter(Nav);