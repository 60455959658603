import React, {useRef, useEffect, useState, useSelector} from 'react';
import {ContainerNoSides, ResponsiveImage, Section, OnlySmall, OnlyLarge, Title, Line, Container} from '../theme'
import Gallery from '../components/Gallery'
import YouTube from 'react-youtube';
import {youtubeParser} from '../components/YoutubeList'

export const ImageGallery = ({images})=>{
    const ref = useRef(null);      
    const [width, setWidth] = useState(null);

    useEffect(() => {
        if (ref.current && width !== ref.current.offsetWidth ){
            setWidth(ref.current.offsetWidth)
        }
      }, [ref.current, width, setWidth]);

    return <Section>
            <OnlySmall>
                <Title>
                    Images
                </Title>
                <Container ref={ref}>
                {images.map((image, idx)=>{
                    if (image._type == "img" ){
                        return <ResponsiveImage key={idx} src={image.image}/>
                    } else {
                        return <YouTube videoId={youtubeParser(image.url)}  opts={{width: width}}/>
                    }})}
                </Container>
            </OnlySmall>
            <OnlyLarge>
                <Section><Line /><Gallery images={images} /></Section>
            </OnlyLarge>
    </Section>
}

export const AllImagesGallery = ({statedata, self, other})=>{
    const [allImages, setAllImages] = useState(null);
    
    useEffect(() => {
        if (statedata.blogposts && allImages == null && statedata.canvaselements){
            let images = [];
            statedata.blogposts.forEach((blogpost)=>{
                if (!self && !other || !self && other && blogpost.member.name == other.name || !other && (statedata.member && blogpost.member.name == statedata.member.name)){
                    blogpost.assets.forEach((asset)=>{
                        if (asset._type == 'img' || asset._type == 'youtube'){
                            images.push(asset);
                        }
                    })
                }
            })
            statedata.canvaselements.forEach((canvaselement)=>{
                if ((self && (statedata.member && canvaselement.member.name == statedata.member.name )) || other && canvaselement.member.name == other.name ){
                    if (canvaselement.data._type == "img" || canvaselement.data._type == "youtube"){
                        images.push({
                            _type: canvaselement.data._type,
                            image: canvaselement.imageurl,
                            url: canvaselement.data.url
                        });
                    }
                }
            })
            setAllImages(images);
        }
    }, [allImages, setAllImages, statedata]);

    return (allImages?<ImageGallery images={allImages}/>:null)

}

const ImageList = ({images})=>{
    const ref = useRef(null);      
    const [width, setWidth] = useState(null);
    
    useEffect(() => {
        if (ref.current && width !== ref.current.offsetWidth ){
            setWidth(ref.current.offsetWidth)
        }
      }, [ref.current, width, setWidth]);


    return(<Section ref={ref}>
            {width?
            images.map((image, index)=>
                <ContainerNoSides key={index}>
                    <ResponsiveImage src={image.image+"?w="+width} onClick={()=>{
                        window.open(image.image, "_blank");
                    }} />
                </ContainerNoSides>
            ):null}
    </Section>)
}
export default ImageList;