import React from 'react';
import Masonry from 'react-masonry-component';
import { galleryImageWidth, galleryBorder, ResponsiveImageWithEffect, defaultWrap, Wrapper,MasonryWrapper } from "../theme";
import YouTube from 'react-youtube';
import {youtubeParser} from '../components/YoutubeList'
import {colors} from '../theme/config.json'

const masonryOptions = {
    transitionDuration: 200,
    columnWidth: galleryImageWidth + galleryBorder * 2,
    gutter: galleryBorder,
    fitWidth: true
};

//todo: set amount
const Gallery = ({columnWidth, images})=>{
        let renderedImages = null;
        masonryOptions.columnWidth = columnWidth || masonryOptions.columnWidth;
        
        renderedImages = images.map((image, i)=>{

            if (image._type == "img"){
                return <MasonryWrapper key={i} width={masonryOptions.columnWidth} onClick={()=>{handleClick(image)}}>
                    <ResponsiveImageWithEffect src={image.image+'?w=400'}  style={{border: defaultWrap}} />
                </MasonryWrapper>
            } else {
                return <MasonryWrapper key={i} width={masonryOptions.columnWidth} onClick={()=>{handleClick(image)}}>
                        <div style={{border: defaultWrap, borderBottom: "0px", display: "inline-block", margin: "0px", background: colors.stek_blue}}>
                            <YouTube videoId={youtubeParser(image.url)} opts={{width: masonryOptions.columnWidth}}/>
                        </div>
                    
                </MasonryWrapper>
            }
        })

        const handleClick = (data) => {
            window.open(data.image, "_blank");
        };
        
        return <Masonry
        elementType={'ul'} // default 'div'
        options={masonryOptions} // default {}
        disableImagesLoaded={false} // default false
        updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
        >
                {renderedImages}
            </Masonry>
    }


export default Gallery;

