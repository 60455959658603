import React, {useState, useEffect} from 'react'
import { useSelector,useDispatch} from "react-redux";
import {Title, Grid, Col, Row, Line, Section, OnlySmall, Text, OnlyLarge, Wrapper, ResponsiveImage, ContainerPadding} from "../theme"
import Button from './Button'
import {LeftIcon, RightIcon, UpIcon, DownIcon, AddIcon} from '../theme/icons';
import styled from 'styled-components';
import LineInput from './LineInput';
import toast from '../services/toast';
import TextEditor from '../components/TextEditor'
import ImageDrop from '../components/ImageDrop'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"

const NavIcon = styled.div`
    font-size: 25px;
    text-align: center;
    font-weight: bold;
    transform: scale(1);
    transition: all .2s ease-in-out;
    &:hover {
        transform: scale(1.4)
    }
`

const CanvasControl = ({canvasSize, setCanvasSize})=>{
    /**
     * consts
     */
    const STATES = {
        INIT: "state/init",
        ADD_RESOURCE: 'state/add',
        ADD_TITLE: 'state/add_title',
        ADD_LINK: 'state/add_link',
        ADD_IMAGE: 'state/add_image',
        ADD_TEXT: 'state/add_text',
        ADD_YOUTUBE: 'state/add_youtube'
    };

    const member = useSelector(state => state.data.member);
    const client = useSelector(state => state.data.client);
    const lastClicked = useSelector(state => state.data.lastClicked);
    

    /**
     * Hooks
     */
    const [state, setState] = useState(STATES.INIT);
    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [content, setContent] = useState(null);
    const [busy, setBusy] = useState(false);
    const dispatch = useDispatch();

    /**
     * Helper functions
     */
    const getOpposite = ()=>(canvasSize==="large"?'small':'large');

    const move =(moverAction)=>{
        dispatch({type: DATA_ACTIONS.SET_MOVER_ACTION, moverAction})
    }

    const getStateChanger = (state)=>{
        return ()=>{setState(state);}
    }

    const clearContent = (msg)=>{
        setTitle('');
        setUrl('');
        setContent(null);
        setBusy(false);
        toast(msg);
        getStateChanger(STATES.INIT)();
    }

    const getLastClicked = ()=>{
        if (lastClicked){
            return lastClicked
        } else {
            return {x:0, y:0}
        }
    }

    const makeCanvasElement = (params)=>{
        setBusy(true);
        client.createCanvasElement(member, getLastClicked().x, getLastClicked().y, params).then(data=>{
            clearContent(`new ${params.type} added`)
        })
    }

    /**
     * Content
     */
    const getDefault = ()=>(<ContainerPadding>
        
            <Row>
                <Col xs={12}> 
                    <Text>{` Current coordinates : (${getLastClicked().x}, ${getLastClicked().y})`}</Text>
                    <br/><br/>
                </Col>
            </Row> 
        
        <Row>
            <Col xs={12}>
                <OnlyLarge>
                    <Button text={`make canvas ${getOpposite()}`} onClick={()=>{setCanvasSize(getOpposite())}}/>
                    <br/>
                </OnlyLarge>
            </Col>
        </Row>
        <Wrapper>
            <ContainerPadding>
                <Row center="xs">
                    <Col xs={5}>
                        <NavIcon onClick={()=>{move('up')}}>
                            <UpIcon />
                        </NavIcon>
                        <br/>
                    </Col>
                </Row>
                <Row center="xs">
                    <Col xs={5}>
                        <NavIcon>
                            <LeftIcon onClick={()=>{move('left')}}/>
                        </NavIcon>
                    </Col>
                    <Col xs={2}>
                        <NavIcon>
                            <AddIcon onClick={getStateChanger(STATES.ADD_RESOURCE)} />
                        </NavIcon>
                    </Col>
                    <Col xs={5}>
                        <NavIcon onClick={()=>{move('right')}}>
                            <RightIcon />
                        </NavIcon>
                    </Col>
                </Row>
                <Row center="xs">
                    <Col xs={5}>
                        <br/>
                        <NavIcon>
                            <DownIcon onClick={()=>{move('down')}}/>
                        </NavIcon>
                        <br/>
                    </Col>
                </Row>
            </ContainerPadding>
        </Wrapper>
    </ContainerPadding>)

    const getCancel = ()=><Button text="cancel" onClick={getStateChanger(STATES.INIT)} options={{noTop:true}}/>

    const getAddResource = ()=>(
        <ContainerPadding>
            <Row center="xs">
                <Col xs={12}>
                    <Button text="add title" onClick={getStateChanger(STATES.ADD_TITLE)} />
                    <Button text="add link" onClick={getStateChanger(STATES.ADD_LINK)} options={{noTop:true}}/>
                    <Button text="add text" onClick={getStateChanger(STATES.ADD_TEXT)} options={{noTop:true}}/>
                    <Button text="add youtube" onClick={getStateChanger(STATES.ADD_YOUTUBE)} options={{noTop:true}}/>
                    <Button text="add image" onClick={getStateChanger(STATES.ADD_IMAGE)} options={{noTop:true}}/>
                    {getCancel()}
                    
                </Col>
            </Row>
        </ContainerPadding>
    )

    const getAddTitle = ()=>(
        <ContainerPadding>
            <LineInput title="TITLE" value={title} setValue={setTitle} />
            <Button text="send" onClick={()=>{ makeCanvasElement({type: "title",title})}} options={{noTop:true}}/>
            {getCancel()}
        </ContainerPadding>
    )

    const getAddLink = ()=>(
        <ContainerPadding>
            <LineInput title="TITLE" value={title} setValue={setTitle} NoBottom />
            <LineInput title="URL" value={url} setValue={setUrl} />
            <Button text="send" onClick={()=>{ makeCanvasElement({type: "link", title, url})}} options={{noTop:true}}/>
            {getCancel()}
        </ContainerPadding>
    )

    const getAddText = ()=>(
        <ContainerPadding>
            <TextEditor value={content} setValue={setContent} />
            <Button text="send" onClick={()=>{ makeCanvasElement({type: "text", content})}} />
            {getCancel()}
        </ContainerPadding>
    )

    const getAddYoutube = ()=>(
        <ContainerPadding>
            <LineInput title="TITLE" value={title} setValue={setTitle} NoBottom />
            <LineInput title="URL" value={url} setValue={setUrl} />
            <Button text="send" onClick={()=>{ makeCanvasElement({type: "youtube", title, url})}} options={{noTop:true}}/>
            {getCancel()}
        </ContainerPadding>
    )

    const getAddImage = ()=>(
        <ContainerPadding>
            <Wrapper>
                <ContainerPadding>
                    <ImageDrop setTitle={setTitle} setValue={setContent} />
                </ContainerPadding>
            </Wrapper>
            <Button text="send" onClick={()=>{ makeCanvasElement({type: "image", title, content})}} options={{noTop:true}}/>
            {getCancel()}
        </ContainerPadding>
    )
    
    if (!member || !client){
        return null;
    } else if (busy){
        return <ContainerPadding><Title>updating</Title></ContainerPadding>
    } else {
        switch (state) {
            case STATES.INIT:
                return getDefault();
            case STATES.ADD_RESOURCE:
                return getAddResource();
            case STATES.ADD_TITLE:
                return getAddTitle();
            case STATES.ADD_IMAGE:
                return getAddImage();
            case STATES.ADD_LINK:
                return getAddLink();
            case STATES.ADD_TEXT:
                return getAddText();
            case STATES.ADD_YOUTUBE:
                return getAddYoutube();
        }
    }    
}

export default CanvasControl;   