import React from 'react';
import Button from '../components/Button'
import {ContainerNoSides} from '../theme'

const LinkList = ({links})=>(
    <ContainerNoSides>
        {links.map((link, index)=><Button key={index} text={link.title} link={link.url} options={{external:true, noBottom: index < links.length-1}} />)}
    </ContainerNoSides>)
    

export default LinkList;
