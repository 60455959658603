import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {colors, fonts, margins, settings} from "../theme/config.json";
import {defaultWrap} from "../theme";


export const ButtonContainer = styled.span`
  display:table;
  width: ${props => props.inline ? 'auto !important' : '100% !important'};
`;
 
export const ButtonSmall = styled.div`
  border: ${settings.borderline}px solid ${colors.textPrimary};
  border-top: ${props=>props.noTop?'0px':null};
  border-bottom: ${props=>props.noBottom?'0px':null};
  width: ${settings.containerpadding}px !important;
  display:table-cell;
  padding: ${settings.containerpadding}px;
  border-left: ${props=>props.noLeft?'0px':null};
  border-right: ${props=>props.noRight?'0px':null};
  background-color: ${colors.primary};
`;

export const ButtonCenter = styled.div`
  padding: ${settings.containerpadding}px;
  border: ${defaultWrap};
  border-top: ${props=>props.noTop?'0px':defaultWrap};
  border-bottom: ${props=>props.noBottom?'0px':defaultWrap};
  border-left: ${props=>props.noSides?'0px':defaultWrap};
  border-right: ${props=>props.noSides?'0px':defaultWrap};
  padding-top: ${settings.containerpadding*1.3}px;
  padding-bottom: ${settings.containerpadding*1.3}px;
  margin-left: ${margins.text.left};
  margin-top: ${margins.text.top};
  text-align: center;
  display:table-cell;
  text-transform: ${fonts.transform.title};
  background-color: ${colors.stek_yellow};
  color: ${colors.stek_blue};
  &:hover {
    color: ${colors.stek_yellow};
    background-color: ${colors.stek_blue};
    cursor: crosshair;
    border: solid ${colors.stek_yellow} 4px;
  }
`;


const Button = ({ inline, link, text, onClick, options, component, altfont }) => {
    options = options || {};
    let hideTop = options.noTop;
    let hideBottom = options.noBottom;
    let hideSides = options.noSides;

    let renderInner = (
        <ButtonCenter noTop={hideTop} noBottom={hideBottom} noSides={hideSides}>
            {component?component():<span style={{fontSize: altfont?"22px":fonts.size.book, fontWeight: options.bold?"bold":"bold", fontFamily: altfont?"VGA":null}}>{text}</span>}
        </ButtonCenter>
    )

    if (link) {
        if (options.external){
            return (
                <a href={link} target="_blank" rel="noopener noreferrer" style={{textDecoration: "none"}} >
                    <ButtonContainer inline = {inline? true : false}>
                    {renderInner}
                    </ButtonContainer>
                </a>
            )
        } else {
            return (
                <Link to={link} style={{textDecoration: "none"}}>
                    <ButtonContainer inline = {inline? true : false}>
                    {renderInner}
                    </ButtonContainer>
                </Link>
            )
        }
    } else if (onClick) {
        return (
            <ButtonContainer inline = {inline? true : false} onClick={onClick}>
                {renderInner}
            </ButtonContainer>
        )
    } else {
        return (
            <h1>this is probs not a button</h1>
        )
    }
}

export default Button