import blockTools from '@sanity/block-tools'
import Schema from '@sanity/schema'
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import blocksToHtml from '@sanity/block-content-to-html';
import htmlToDraft from 'html-to-draftjs';


const defaultSchema = Schema.compile({
    name: 'x',
    types: [
      {
        type: 'object',
        name: 'y',
        fields: [
          {
            title: 'Title',
            type: 'string',
            name: 'title'
          },
          {
            title: 'Body',
            name: 'body',
            type: 'array',
            of: [{type: 'block'}]
          }
        ]
      }
    ]
  })

export const getBlockContentType = ()=>defaultSchema.get('y').fields.find(field => field.name === 'body').type
export const draftToBlock = (content)=>blockTools.htmlToBlocks(draftToHtml(convertToRaw(content)), getBlockContentType())
export const blockToDraft = (content)=>htmlToDraft(blocksToHtml({blocks: content}));
export const htmlToBlock = (content)=>blockTools.htmlToBlocks(content, getBlockContentType())
export const BlocktoPlainText=(blocks = [])=>{
  return blocks
    // loop through each block
    .map(block => {
      // if it's not a text block with children, 
      // return nothing
      if (block._type !== 'block' || !block.children) {
        return ''
      }
      // loop through the children spans, and join the
      // text strings
      return block.children.map(child => child.text).join('')
    })
    // join the parapgraphs leaving split by two linebreaks
    .join('\n\n')
}
