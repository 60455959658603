import React, {useState, useEffect} from 'react';
import { useSelector} from "react-redux";
import {CircleImage, CircleImageWithEffect, Row, Col, Text, Container, ContainerSides, Section,Wrapper, ContainerPadding, Title, InlineText} from '../theme'
import {SendIcon, CloseIcon, WhatsappIcon} from '../theme/icons'
import BlockContent from '@sanity/block-content-to-react'
import Button from '../components/Button'
import TextEditor from '../components/TextEditor'
import ImageDrop from '../components/ImageDrop'
import Expand from 'react-expand-animated';
import LineInput from '../components/LineInput';
import {colors, medias} from '../theme/config.json'
import styled from 'styled-components';

export const ResponsivePadder = styled.div`
    padding: ${props=>props.padding?props.padding:"0"||"0"}px;
    @media ${medias.onlysm} {
        padding: 0px;
      }

`

const MemberOverview = ({otherMember, expander, sizeSmall, padding, name, noVisit})=>{
    const [edit, setEdit] = useState(false);
    const [content, setContent] = useState(null);
    const [updating, setUpdating] = useState(false);
    const member = useSelector(state => state.data.member);
    const client = useSelector(state => state.data.client);
    const [avatarTitle, setAvatarTitle] = useState('');
    const [avatarData, setAvatarData] = useState(null);
    const [whatsappValue, setWhatsappValue] = useState(null);
    const [open, setOpen] = useState(false);
    const [transit, setTransit] = useState(false);
    const duration = 400;

    useEffect(() => {
        if (member && member.whatsapp && whatsappValue==null){
            setWhatsappValue(member.whatsapp);
        }
        
    });

    const toggle = ()=>{
        setOpen(!open);
        setTransit(true);
        setTimeout(() => {
            setTransit(false);
        }, duration);
    }
    sizeSmall = sizeSmall || {xs: 12, sm: 12, md: 12, lg:12};
    padding = padding || 0;

    const save=()=>{
        setUpdating(true);
        client.updateBio(content, avatarData, whatsappValue, member)
        .then((updatedmember)=>{
            client.refreshMember(member);
            setEdit(false);
            setUpdating(false);
        })
    }

    const getSrc= (avatar)=>avatar?client.squareImage(avatar, 400):"https://i.ibb.co/PYsksgQ/01.png"

    const getRegularContent = (avatar, bio, slug, edit, membername, whatsapp)=>
        <Col xs={open||transit?12:sizeSmall.xs} sm={open||transit?12:sizeSmall.sm} md={open||transit?12:sizeSmall.md} lg={open||transit?12:sizeSmall.lg}>
            <ResponsivePadder padding={padding}>
                <Row center="xs">
                    <Col xs={8} sm={6} md={12}>
            {expander? <CircleImageWithEffect src={getSrc(avatar)} onClick={toggle}/>
            : <CircleImage src={getSrc(avatar)} />}
            </Col>
            </Row>

            <Expand open={!expander || open} >
                <React.Fragment>
                    {name? <Title>{membername}</Title>:null}
                    <Container>
                        <Wrapper style={{background: colors.stek_yellow}}>
                            <ContainerSides>
                            <InlineText>
                                <BlockContent blocks={bio} />
                            </InlineText>
                        {member && whatsapp? <React.Fragment>
                            <br/>
                            <InlineText>  
                                <WhatsappIcon/> 
                                <a href={"https://api.whatsapp.com/send?phone="+whatsapp}>{whatsapp}</a>
                            </InlineText>
                        </React.Fragment> : null}
                        <br/><br/>
                            </ContainerSides>
                        </Wrapper>
                        
                        <br />
                    {edit?
                        <Section>
                        <br />
                        <Button text="edit" onClick={()=>{setEdit(true)}} /></Section>    
                        :null}
                    {(noVisit || edit)?null:<React.Fragment><Button text="visit page" link={`/member/${slug}`}/><br/><br/></React.Fragment>}
                    </Container>
                </React.Fragment>    
            </Expand>
            </ResponsivePadder>
        </Col>
    

    const getEditContent = ()=><Row>
        <Col xs={12}>
            <Wrapper noBottom>
                <TextEditor value={content} setValue={setContent} blocks={member.bio?member.bio:null}/>
                <br/><br/>
                <ContainerPadding>
                    <Wrapper>
                        <Container>
                            <ImageDrop setTitle={setAvatarTitle} setValue={setAvatarData} text={"Drag 'n drop een nieuwe avatar hier of click om te selecteren."} />
                        </Container>
                    </Wrapper>

                    <LineInput title="Whatsapp" value={whatsappValue} setValue={setWhatsappValue} />
                </ContainerPadding>
            </Wrapper>
        </Col>
        <Col xs={12}>
            <Button component={CloseIcon} onClick={()=>{setEdit(false)}} options={{noBottom:true}} />
            {updating?
            <Button text={"updating"} onClick={()=>{}} /> : 
            <Button component={SendIcon} onClick={()=>{save()}} />}
            
        </Col>
    </Row>

    if (client && otherMember!==undefined){
        return getRegularContent(otherMember.avatar, otherMember.bio, otherMember.slug, false, otherMember.name, otherMember.whatsapp)
    } else if (member){
        return (edit?getEditContent():getRegularContent(member.avatar, member.bio, member.slug, true, member.name, member.whatsapp))
    } else {
        return null;
    }
}

export default MemberOverview;