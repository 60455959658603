import React, {useState, useEffect} from 'react';
import { useSelector} from "react-redux";
import Blogs from '../components/Blogs'
import { Grid, Col, Row, Wrapper, Title, Container, ContainerPadding, Section, CircleImageWithEffect } from '../theme';
import MemberOverview from '../components/MemberOverview'


const Members =  ()=>{
    const [members, client] = useSelector(state=>[state.data.members, state.data.client]);

    if (!members || !client){
        return null;
    } else {
        return <ContainerPadding>
                <Row center="xs"> 
                    {members.map((member, index)=><MemberOverview padding={10} name otherMember={member} sizeSmall={{xs: 6, sm: 6, md: 6, lg:4}} expander key={member.name}/>)}
                </Row>
            </ContainerPadding>
    }   
}

export default Members;

