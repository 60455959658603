import React, {useEffect} from 'react';
import { Provider } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from 'history';
import Store from "../Store";
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import sanityclient from '../services/client';
import { css } from 'glamor';
import '../assets/css/style.css';
import '../assets/css/react-grid-layout.css'
import '../assets/css/react-resizable.css'
import Nav from './Nav'
import { CookiesProvider } from 'react-cookie';
import MemberPage from '../pages/memberpage'
import BlogPage from '../pages/blogpage';
import {ThemeProvider} from 'styled-components'
import Home from '../pages/homepage'
import {colors} from '../theme/config.json'
import {defaultWrap} from '../theme'
import 'react-toastify/dist/ReactToastify.css';


/**
 * Make memberlist to show on the landingspage 
 * Make inidividual memberpage
 * Make about page
 */


const DataHelper = () => {
  const data = useSelector(state => state.data);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!data.client && dispatch){
      const cli = sanityclient(dispatch);
      toast.configure()
      dispatch({ type: DATA_ACTIONS.SET_CLIENT, client: cli});
      cli.fetchAllInitialData();
    } 
  })
  return (
    null
  )
}

const Toaster = ()=>{
  const CloseButton = ({ YouCanPassAnyProps, closeToast }) => (
    <i onClick={closeToast}></i>
  );
  return <ToastContainer
    // className={css({fontSize: "17px"})}
    // toastClassName={css({color:colors.stek_blue, border: defaultWrap, background: colors.stek_yellow})}
    closeButton={<CloseButton/>}
  />
}

const theme = {
  flexboxgrid: {
    // Defaults
    gridSize: 12, // columns
    gutterWidth: 0, // rem
    outerMargin: 2, // rem
  }
}



const history = createBrowserHistory()

const App =()=>{
  return (
    <Provider store={Store}>
      <CookiesProvider>
        <DataHelper />
        <Toaster />
        <ThemeProvider theme={theme}>
          <Router history={history}>
            <span>
              <Nav history={history} member={Store.member}/>
              <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/member" component={MemberPage} />
                <Route exact path="/member/:slug" component={MemberPage} />
                <Route exact path="/blog/:id" component={BlogPage} />
                <Route component={Home}/>
              </Switch>
            </span>
          </Router>
          <div style={{width: "100%", textAlign: "center", color: colors.stek_blue  }}>
            Copyright © 2020 <a target="_blank" href="https://www.technopolis.be/" style={{textDecoration: "none"}}>Technopolis</a> / <a target="_blank" href="https://seads.network" style={{textDecoration: "none"}}>SEADS</a> - Made with ♥ by Studio Subtiv powered by <a  target="_blank" href="https://seads.network" style={{textDecoration: "none"}}>SEADS</a>
            <br/><br/><br/>
          </div>
        </ThemeProvider>
      </CookiesProvider>
  </Provider>
  );
}

export default App;
