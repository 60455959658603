import styled from 'styled-components';
import "circular-std";
import {colors, fonts, gallery, margins, settings, medias} from "./config.json";
import {Grid as Grid_, Col as Col_, Row as Row_} from 'react-styled-flexboxgrid';
import {ReactSVG} from 'react-svg';
import { nanoid } from 'nanoid'


//MAIN
// -- Grid

export const Gridder = Grid_;
export const Coller = styled(Col_)`
  margin-bottom: ${props => props.marginBottom ? margins.div.general : null};
`;
export const Rower = Row_;
export const Grid = Grid_;
export const Row = Row_;
export const Col = Col_;

export const Section = styled.div``

export const defaultWrap = `solid ${colors.stek_blue} 4px`

export const Wrapper = styled.div`
    border: ${defaultWrap};
    border-bottom: ${props=>props.noBottom?'0px':null};
    border-top: ${props=>props.noTop?'0px':null};
`

export const OnlySmall = styled.div`
  @media ${medias.onlysm} {
    display: inline;
  }
  display: none;
`
export const OnlyLarge = styled.div`
  @media ${medias.onlysm} {
    display: none;
  }
  display: inline;
`

export const MediumMax = styled.div`
  @media ${medias.mdandup} {
    display: inline;
  }
  display: none;
`
export const MediumMin = styled.div`
  @media ${medias.mdandup} {
    display: none;
  }
  display: inline;
`

export const MainContainer = styled(Grid)`
  margin-top: 60px;
`;

export const LogoContainer = styled.div`
  width: 100% !important;
`;

export const Container = styled.div`
  margin: ${props => props.size? props.size : "30px"};
  margin-bottom: ${props => props.NoBottom? null : props.size? props.size : "30px"};
  margin-top: ${props => props.NoTop? null : props.size? props.size : "30px"};
  background: white;
  @media ${medias.onlysm} {
    margin:  10px;
  }
`;

export const ContainerSides = styled.div`
  margin-left: ${props => props.size? props.size : "30px"};
  margin-right: ${props => props.size? props.size : "30px"};
  margin-top: 0px;
  margin-bottom: 0px;
  @media ${medias.onlysm} {
    margin-left: 10px;
    margin-right: 10px;
  }
`

export const ContainerNoSides = styled.div`
  margin-left: 0px;
  margin-right: 0px;
  margin-top: ${props => props.size? props.size : "30px"};
  margin-bottom: ${props => props.size? props.size : "30px"};
`

export const ContainerPadding = styled.div`
  padding: ${props => props.size? props.size : "30px"};
  padding-bottom: ${props => props.noBottom? "0px" : props.size? props.size : "30px"};
  @media ${medias.onlysm} {
    padding: 10px;
    padding-bottom: 10px;
  }
`;

export const Footer = styled.div`
  background: black;
  color: white;
  width: 100%;
`;


export const Smallbreak = styled.br`
  display: block;
  margin: 10px 0;
  content: " ";
`;


export const Logo = styled.div`
  margin-left: ${props => props.smalll? "50px" : margins.logo};
  margin-right: ${props => props.smalll? "50px" : margins.logo};
`;

export const LogoSmall = styled.div`
  margin: 100px;
  margin-top: 0px;
  margin-bottom: 0px;
`;


// -- Typo
export const Title = styled.h1`
  color: ${props => props.color ||  colors.stek_blue};
  font-size: ${fonts.size.title};
  text-align: ${props => props.align ||  fonts.align.title};
  font-weight: ${fonts.weight.title};
  text-transform: ${fonts.transform.title};
`;

export const AltTitle = styled(Title)`
  font-family: 'VGA';
  color: ${props => props.color ||  colors.stek_blue};
`

export const SubTitle = styled.h2`
  font-size: ${fonts.size.subtitle};
  text-align: ${props => props.align ||  fonts.align.title};
  color: ${props => props.color ||  colors.stek_blue};
`;


export const NoLineA = styled.a`
  text-decoration: none;
`

export const IconContainer = styled.div`
  display: inline-block;
  height: 100%;
  color: white;
  padding: 10px;
  background-color: black;
  width: 30px;
`;

export const InlineText = styled.span`
  color: ${props => props.color ||  colors.stek_blue};
  font-size: ${fonts.size.book};
  text-align: ${props => props.align ||  fonts.align.book};
  font-weight: ${fonts.weight.book};
`

export const Text = styled.div`
  padding: 20px;
  text-align: ${props => props.align ||  "left"};
  color: ${props => props.color ||  colors.stek_blue};
  font-size: ${fonts.size.book};
  font-weight: ${fonts.weight.book};
`;



export const ListItem = styled.li`
  font-size: ${fonts.size.listItem};
  text-align: ${props => props.align ||  fonts.align.listItem};
  font-weight: ${fonts.weight.listItem};
  text-transform: ${fonts.transform.listItem};
`;



export const ErrorMessage = styled.h3`
  font-size: 1em;
  text-align: center;
  color: ${props => props.color ||  'black'};
`;


export const Image = styled.img`
`;

export const ResponsiveImage = styled(Image)`
  width: 100% !important;
  transition: all .2s ease-in-out;
  height: ${props => props.height || null};
  object-fit: contain;
`;



export const ResponsiveImageWithEffect = styled(ResponsiveImage)`
&:hover {
  transform: scale(.9);
}
`;

export const Overlay = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 999;
  left: 0;
  top: 0;
`;

export const CenterContainer = styled.div`
  position: relative;
  top: 50%; /* 25% from the top */
  width: 100%; /* 100% width */
  text-align: center; /* Centered text/links */
  transform: translateY(-50%);
`;

export const Line = styled.hr`
  border-top: ${defaultWrap};
  margin: 0px;
  padding: 0px;
`;

export const Label = styled.label`
border: ${settings.borderline}px solid ${colors.textPrimary};
margin: 10px;
padding: 10px;
display:block
`

//BUTTON


export const Icon = styled(ReactSVG)`
  width: 25px;
  height: 25px;
  color: red;
  @media only screen and (min-width: ${medias.smp}px) {
    width: 20px;
    height: 20px;
  }
  
`;



export const ClickIcon = styled(Icon)`
  :hover {
    cursor: pointer
  }
`;

export const Panel = styled.div`
  border: ${settings.borderline}px solid ${colors.textPrimary};
  border-bottom: ${props => props.noBorderBottom ? 'none' : `${settings.borderline}px solid ${colors.textPrimary}`}
  margin: 0em;
  margin-top: ${margins.text.top};
  background-color: ${props=>props.backgroundColor || "transparant"};
`;

export const PanelTitle = styled.h3`
  border-bottom: ${props => props.noBorderBottom ? 'none' : '2px solid black'}
  font-size: 1em;
  text-align: left;
  color: ${props => props.color ||  'black'};
  margin: 0em;
  padding: 0.2em 0.5em;
  background-color: ${props=>props.backgroundColor || "transparant"};
`;
//COMPONENT SPECIFIC

//LANDING
export const LandingsPanel = styled.div`
  background-color: white;
  position: absolute;
  width: 450px;
  right: 200px;
  padding:  20px;
  padding-right: 30px;
`;

export const BackgroundLanding = styled.div`
    position: absolute;
    top: 25px;
    width: 100% !important;
    z-index: -1;
`;

//Portfolio
export const Bordered = styled.div`
  border:  4px solid black;
`;

//ImageTile
export const SquareImage = styled.div`
  background: url(${props => props.img}) no-repeat center center;
  background-size: cover;
  border-bottom: ${props => props.borderBottom ? "2px solid black" : "none"};
  display: inline-block;
  width: ${props => props.width ||  '50%'};
  padding-bottom: ${props => props.width ||  '50%'};
  transition: all .2s ease-in-out;
`

export const SquareImage2 = styled.td`
  height: ${props => props.width};
  width: ${props => props.width};
  background-image: url(${props => props.image})
  background-size: 100% auto;
  //background-size: cover;
  // background-repeat: no-repeat;
  // background-size: 50px 50px;
`

export const SquareImageWithEffect = styled(SquareImage)`
&:hover {
  transform: scale(.9);
}
`;

//MemberList
export const CircleImage = styled(Image)`
  width: ${props=>props.width?props.width:"100% !important"};
  border-radius: 50%;
  transition: all .2s ease-in-out;  
  border: ${props=>props.noWrap?null:defaultWrap||defaultWrap};
`;

export const CircleImageWithEffect = styled(CircleImage)`
&:hover {
  transform: scale(.9);
}
`;

export const MemberListContainer = styled.div`
  margin: 0 auto;
`

//Gallery
export const galleryImageWidth = gallery.imagewidth;
export const galleryBorder = gallery.border;
export const MasonryWrapper = styled.div`
  width: ${props => props.width ? props.width : gallery.imagewidth}px;
  margin-bottom: ${gallery.border}px;
`;

//Navigation




//Calendar
// export const CalendarItem = styled.span`
//     margin-bottom: 10px;
// `

// export const SmallSpacer = styled.div`
//   height:4pt; 
//   visibility:hidden;
// `

export const CalendarTable = styled.table`
margin-top: 20px;
table-layout: fixed;
width: 100%;
td:nth-child(1){
  width: 100px;
  vertical-align: top;
  text-align: left;
}
td:nth-child(2){
  padding-bottom: 8px;
}
`





