import React from 'react';
import SCQuery from '../components/SCQuery'
import styled from 'styled-components';
import {colors} from '../theme/config.json'

export const CalendarTable = styled.table`
margin-top: 20px;
table-layout: fixed;
width: 100%;
color: ${colors.stek_blue};
text-decoration: none;
td:nth-child(1){
  width: 100px;
  vertical-align: top;
  text-align: left;
}
td:nth-child(2){
  padding-bottom: 8px;
}
`


const CalendarComponent = ({events})=>{
    const now = new Date();
    events.sort((a, b) => (new Date(a.moment) - new Date(b.moment)));
    events.map((event) => (
        event.moment = new Date(event.moment)
    ));
    events = events.filter((event)=>{
        let currentMonth = now.getMonth();
        const regular = ((event.moment.getYear() >= now.getYear()) && (now.getMonth()-1 <= event.moment.getMonth()));
        switch(currentMonth) {
            case 0:
                return regular || (event.moment.getYear() === now.getYear() && event.moment.getMonth() === 11);
            default:   
                return regular;

        }
    });
    const renderedEvents = [];
    let counter = 0;
    events.forEach((event) => {
        if (true) { //calendar events
            let date = event.moment;

            let fn = (nr) => ("0" + nr).slice(-2);

            renderedEvents.push(
                <tr key={event.title}>
                    <td style={{fontFamily: 'VGA', transform: "translateY(5px)"}}>
                        {fn(date.getDate())}/{fn(date.getMonth()+1)}/{date.getFullYear() - 2000}
                    </td>
                    <td>
                        <a href={event.url} style={{textDecoration: "none"}}>
                            <b>
                                {event.title}
                            </b>
                        </a>
                    </td>
                </tr>
            );
        }
    });
    return (
        <CalendarTable >
            <tbody >
                {renderedEvents}
            </tbody>
        </CalendarTable>
    )
}

const Calendar = () => {
    return <SCQuery query={"*[_type == 'event']{title, url, moment}"}>
        {(events)=><CalendarComponent events={events}/>}
    </SCQuery>
}


export default Calendar;

