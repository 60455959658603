import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch} from "react-redux";
import Protected from '../components/Protected'
import {Title, Grid, Col, Row, Line, Section, OnlySmall, OnlyLarge, ResponsiveImage, Container, ContainerPadding} from "../theme"
import BlogPostMaker from '../components/BlogPostMaker';
import MemberOverview from '../components/MemberOverview';
import CollapisebleContainer from '../components/CollapsibleContainer';
import Blogs from '../components/Blogs'
import {AllImagesGallery} from '../components/ImageList'
import Map from '../components/Map';
import MapElements from '../components/MapElements';
import toast from '../services/toast';
import CanvasControl from '../components/CanvasControl';
import Titles from '../components/Titles'
import CollapsibleContainer from '../components/CollapsibleContainer';
import {LinkCard, Links} from '../components/Links'
import Texts from '../components/Texts'
import Youtubes from '../components/Youtubes'
import Images from '../components/Images'
import Members from '../components/members'
import Calendar from '../components/Calendar'
import SCQuery from '../components/SCQuery'
import MetaTags from 'react-meta-tags';
import {BlocktoPlainText} from '../services/BlockHelper' 
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"

const Memberpage =  ({match})=>{
    const statedata = useSelector(state => state.data);
    const mapref = useRef(null);
    const [canvasClicked, setCanvasClicked] = useState(null);
    const [canvasSize, setCanvasSize] = useState('small');
    const [canvasOpen, setCanvasOpen] = useState(true);
    const [mapWidth, setMapWidth] = useState(null);
    const [mapElementsRendered, setMapElementsRendered] = useState(false);
    const dispatch = useDispatch();


    useEffect(() => {
        if (mapref && mapref.current && !mapWidth){
            setMapWidth(mapref.current.clientWidth)
        }

        const handleResize = ()=>{
            setMapWidth(null);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    });


    const getBlogsComponent = (self, other)=><CollapisebleContainer title='blogs' noPadding beginOpen={!self}>
                                        <Blogs initialAmount={3} self={self} other={other}/>
                                    </CollapisebleContainer>
    // const getControlComponent = ()=>{
    //     return <CollapisebleContainer title='canvas control' beginOpen noPadding >
    //                                     <CanvasControl canvasSize={canvasSize} setCanvasSize={(size)=>{
    //                                         setCanvasSize(size);
    //                                         setMapWidth(null);
    //                                     }} />
    //                                 </CollapisebleContainer>
    // }

    // const getCanvasComponent = (title, component)=>{
    //     return <CollapsibleContainer title={title} noPadding>
    //         {component}
    //     </CollapsibleContainer>
    // }

    // const getTitlesComponent = ()=>{
    //     return getCanvasComponent("Titles", <Titles />)
    // }

    // const getLinksComponent = ()=>{
    //     return getCanvasComponent("Links", <Links />)
    // }

    // const getTextsComponent = ()=>{
    //     return getCanvasComponent("Texts", <Texts />)
    // }

    // const getYoutubesComponent = ()=>{
    //     return getCanvasComponent("Youtube videos", <Youtubes />)
    // }

    // const getImagesComponent = ()=>{
    //     return getCanvasComponent("Images", <Images />)
    // }

    // //WORK IN PROGRESS
    // const getClicked = () => {
    //     let component = null;
    //     const ce = canvasClicked;
    //     console.log(canvasClicked.data._type);
    //     switch (canvasClicked.data._type) {
    //         case 'link':
    //             component = 
    //             <LinkCard  member={ce.member} updatedAt={ce._updatedAt} element = {ce} action={()=>{ dispatch({type: DATA_ACTIONS.SET_GOTO_CANVAS_POSITION, gotoCanvasPosition: {x: ce.x, y: ce.y}})}} x={ce.x} y={ce.y}
    //             actionname="focus in canvas"
    //             url={ce.data.url}
    //             />;
    //             break;
    //         default: 
    //             component = null
    //     }

    //     console.log('Component', component)
    //     return (
    //         <CollapisebleContainer title='Canvas editor' beginOpen noPadding >
    //             {component}
    //         </CollapisebleContainer>
    //     )
    // }

    // const updateElementClicked = (el) => {
    //     const id = el.elt.dataset.id;
    //     const clicked = statedata.canvaselements.find(el => el._id == id);
    //     setCanvasClicked(clicked);
    //     console.log('Clicked in memberpage', id);
    //     console.log('Clicked element', clicked);

    //     toast(`You clicked X: ${el.x}, Y: ${el.y}, ID: ${ el.elt.dataset.id}`);

    //     //Can I render a single component based on ID? 

    //     /* return ((x, y)=>{
    //         toast(`You clicked X: ${x}, Y: ${y}`);
    //     }) */
    // }

    // const updateElementPosition = (el) => {
    //     console.log('Update element position in DB');
    //     toast(`Shoould update X: ${el.x}, Y: ${el.y} for ID: ${ el.elt.dataset.id}`);
    // }

    if (match.params && match.params.slug && statedata.member && statedata.member.slug !== match.params.slug){
        return <SCQuery single query={`*[_type == 'member' && slug.current == "${match.params.slug}"][0]{_id, name, 'avatar':avatar.asset->url, bio, whatsapp}`}>
                    {(member)=>{   
                        return(
                            <React.Fragment>
                                <MetaTags>
                                    <title>{"_STEK/// PACK / " + member.name}</title>
                                    <meta name="description" content={BlocktoPlainText(member.bio)} />
                                    <meta property="og:title" content={"_STEK/// PACK" + member.name} />
                                    <meta property="og:image" content={member.avatar+"?w=200"} />
                                </MetaTags>
                            <Grid >
                                <Row left={"xs"}>
                                    <Col xs={12} sm={12} md={6} lg={4}>
                                        <br/><br/>
                                        <MemberOverview otherMember={member} noVisit={true} name={true}  />
                                        <CollapsibleContainer title="STEK PACK" noPadding>
                                            <Members />
                                        </CollapsibleContainer>
                                    </Col>
                                    <Col xs={12} sm={12} md={6} lg={8}>
                                        {getBlogsComponent(false, member)} 
                                    </Col>
                                </Row>
                            </Grid>
                            <AllImagesGallery statedata={statedata} self={false} other={member} />
                            </React.Fragment>
                        )}}
        </SCQuery>
    } else {
        return <Protected>
            {statedata && statedata.member && statedata.member.name ?
            <MetaTags>
            <title>{"_STEK/// PACK / " + statedata.member.name}</title>
            <meta name="description" content={BlocktoPlainText(statedata.member.bio)} />
            <meta property="og:title" content={"_STEK/// PACK" + statedata.member.name} />
            <meta property="og:image" content={statedata.member.avatar+"?w=200"} />
        </MetaTags>
            
            : null}
        <Grid style={{margin: 0, padding: 0, width: "100%"}}>
            <Row left={"xs"}>
                <Col xs={12} sm={12} md={6} lg={4}>
                    <CollapisebleContainer title={statedata.member? statedata.member.name : ''}>
                        <MemberOverview member={statedata.member} />
                    </CollapisebleContainer>
                    <BlogPostMaker  />
                    <OnlySmall>
                        <CollapsibleContainer title="STEK PACK">
                            <Members />
                        </CollapsibleContainer>
                        {getBlogsComponent(true)} 
                    </OnlySmall>
                    <CollapsibleContainer title="Calendar" beginOpen>
                        <Calendar />
                    </CollapsibleContainer>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                    <OnlyLarge>
                        <CollapsibleContainer title="STEK PACK">
                            <Members />
                        </CollapsibleContainer>
                        {getBlogsComponent(true)} 
                    </OnlyLarge>
                </Col>
            </Row>
        </Grid>
        {/* <Line /> */}
        {/* <Grid style={{width: "100%", margin: "0px", padding: "0px"}}>
            <Row center="xs">
                <Col xs={12} md={4}>
                    {getControlComponent()}
                    {canvasClicked ? getClicked() : null }
                    {canvasSize==="small"? 
                    getTitlesComponent()
                    : null}
                </Col>
                {canvasSize==="large"? <Col xs={12} md={4}>
                    {getTitlesComponent()}
                </Col>: null}
                <Col xs={12} md={canvasSize==="large"?12:8}>
                    <CollapisebleContainer title="Canvas" beginOpen={canvasOpen} onChange={setCanvasOpen} noPadding >
                        <Section id="canvas-elements" ref={mapref} style={{paddingLeft: "0px", marginRight: "11px", position: "relative"}}>
                        {mapElementsRendered && mapWidth && canvasOpen?
                            <Map width={mapWidth} clicked={updateElementClicked} updateElementPosition={updateElementPosition} dispatch={dispatch}  /> : null}
                        {statedata.canvaselements ? 
                            <MapElements onRender={setMapElementsRendered}/> : null 
                        }
                        </Section>
                    </CollapisebleContainer>
                </Col> 
               
                <Col xs={12} md={4}>
                    {getLinksComponent()}
                </Col>
                <Col xs={12} md={4}>
                    {getTextsComponent()}
                </Col>
                <Col xs={12} md={4}>
                    {getYoutubesComponent()}
                </Col>
                <Col xs={12} md={4}>
                    {getImagesComponent()}
                </Col>
                
            </Row>
        </Grid> */}
        <AllImagesGallery statedata={statedata} self={true} />
        
</Protected>}

    }



export default Memberpage;