import React, {useState, useEffect} from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Section} from '../theme'
import './TextEditor.css';
import { EditorState, ContentState } from 'draft-js';
import {draftToBlock, blockToDraft} from '../services/BlockHelper'
import htmlToDraft from 'html-to-draftjs';

const TextEditor = ({blocks, html, value, setValue})=>{

    const toolbar = {
        options: ['inline', 'blockType', 'list','link', 'history', 'emoji'],
        inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
    }

    let draftcontent;

    try {
        if (blocks){
            draftcontent = blockToDraft(blocks);
        } else if (value){
            draftcontent = blockToDraft(value);
        } else if (html){
            draftcontent = htmlToDraft(html);
        } else {
            draftcontent = htmlToDraft('');
        }    
    } catch (error) {
        draftcontent = htmlToDraft('');
    }

    const contentState = ContentState.createFromBlockArray(draftcontent.contentBlocks);
    const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
    const [prevBlock, setPrevBlock] = useState(null);

    useEffect(() => {
        if (blocks && blocks.length>0){
            if (!prevBlock || prevBlock.length==0 || prevBlock[0]._key !== blocks[0]._key){
                let newcontentstate =  ContentState.createFromBlockArray(blockToDraft(blocks).contentBlocks)
                setEditorState(EditorState.createWithContent(newcontentstate));
                setPrevBlock(blocks);
            }
        } 
    });
    
    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState);
        setValue(draftToBlock(editorState.getCurrentContent()));
    }

    return <Section>
            <Editor
                toolbar={toolbar}
                toolbarClassName="Toolbar"
                wrapperClassName="TextEditorWrapper"
                editorClassName="Editor"
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
            />
        
        {/* <BlockContent blocks={draftToBlock(editorState.getCurrentContent())}/> */}
        
    </Section>
}


export default TextEditor;