export const ACTIONS = Object.freeze({
    SET_CLIENT: Symbol("data/client"),
    SET_MEMBER: Symbol('data/member'),
    SET_BLOGPOSTS: Symbol('data/blogposts'),
    SET_CANVAS_ELEMENTS: Symbol('data/canvaselements'),
    SET_MOVER_ACTION: Symbol('data/mover'),
    SET_LAST_CLICKED: Symbol("data/lastclicked"),
    SET_GOTO_CANVAS_POSITION: Symbol('data/gotocpos'),
    SET_MEMBERS: Symbol('data/members')

});

const initialState = {
  client: null,
  member: null,
  blogposts: null,
  canvaselements: null,
  moverAction: null,
  lastClicked: null,
  gotoCanvasPosition: null,
  members: null
};

export default (state = initialState, action) => {
  
  switch (action.type) {
    case ACTIONS.SET_CLIENT: {
      return {
        ...state,
        client: action.client
      };
    }
    case ACTIONS.SET_MEMBER: {
      return {
        ...state,
        member: action.member
      };
    }
    case ACTIONS.SET_BLOGPOSTS:
      return {
        ...state,
        blogposts: action.blogposts
      }
    case ACTIONS.SET_CANVAS_ELEMENTS:
      return {
        ...state,
        canvaselements: action.canvaselements
      }
    case ACTIONS.SET_MOVER_ACTION:
      return {
        ...state,
        moverAction: action.moverAction
      }
    case ACTIONS.SET_LAST_CLICKED:
      return {
        ...state,
        lastClicked: action.lastClicked
      }
    case ACTIONS.SET_GOTO_CANVAS_POSITION:
      return {
        ...state,
        gotoCanvasPosition: action.gotoCanvasPosition
      }
    case ACTIONS.SET_MEMBERS:
      return {
        ...state,
        members: action.members
      }
    default: {
      return {
        ...state
      };
    }
  } 
};
