import React, { Component } from "react";
import Sketch from "react-p5";
import { connect } from 'react-redux';
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"

//if more load from a settings file
const MOVE_SCALE = 50;

class Map extends Component {

  constructor(props) {
    super(props);
    this.setComm = props.setComm;
    this.state = {
      center: {
        x: 0,
        y: 0
      },
      dragging: null,
      height: 500,
      width: 500, 
      clicked: props.clicked,
    }

    this.dispatch = props.dispatch;
    this.updateElementPosition = props.updateElementPosition;

    this.canvas = null;
    this.dragging = {
      el: null,
      onsetX: 0,
      onsetY: 0
    };
    this.htmlContent = [];

    if (props.width){
        this.state.width = props.width;
        this.state.height = props.width * 0.8;
    } 
  }

  componentWillUpdate({width, moverAction, gotoCanvasPosition}){
    
    if (moverAction){
      this.move(moverAction)
      this.dispatch({type: DATA_ACTIONS.SET_MOVER_ACTION, moverAction: null});
    }

    if (gotoCanvasPosition){
      console.log('canvas should move to this position:', gotoCanvasPosition);
      this.moveCenterTo(gotoCanvasPosition.x, gotoCanvasPosition.y);
      this.dispatch({type: DATA_ACTIONS.SET_GOTO_CANVAS_POSITION, gotoCanvasPosition: null});
    }

    if (width && width!=this.state.width){
        if (this.state.p5 && this.state.canvasParentRef){
            this.createCanvas(this.state.p5, this.state.canvasParentRef, width);
            this.setState({...this.state, width})
        }
    }
  }

  /* P5 BASE FUNCTIONS */
  setup = (p5, canvasParentRef) => {      
    this.setState({...this.state, canvasParentRef, p5})
    this.createCanvas(p5, canvasParentRef, this.state.width);

    //getting and drawing content
    this.getContent(p5);
  };

  draw = (p5) => {
    p5.background("white");
    p5.fill("white");
    p5.ellipse(p5.mouseX, p5.mouseY, 20, 20);
    p5.fill("red");
    const relCenter = this.getRelativePosition(this.state.center.x, this.state.center.y);
    p5.ellipse(relCenter.x, relCenter.y, 20, 20);

    if(this.dragging.el) {
      if(this.dragging.el === 'world') {

      } else {
        console.log('Dragging element', this.dragging.el);
        if(p5.mouseX + this.dragging.onsetX < this.canvas.width && p5.mouseX + this.dragging.offsetX > 0){
          this.dragging.el.position(p5.mouseX + this.dragging.offsetX, this.dragging.el.y);
        }
        if(p5.mouseY + this.dragging.onsetY < this.canvas.height && p5.mouseY + this.dragging.offsetY > 0 ){
            this.dragging.el.position(this.dragging.el.x, p5.mouseY + this.dragging.offsetY);
        }
      }
    }
  };

  /* P5 CUSTOM FUNCTIONS */
  createCanvas = (p5, canvasParentRef, width)=>{
    let height = Math.min(width, window.innerHeight*.8);
    this.canvas = p5.createCanvas(width, height).parent(canvasParentRef); 
    
    this.state.height = height;
  }

  drawContent = () => {
    this.htmlContent.forEach(el => {
      el.position(el.relX, el.relY);
    });
  }

  endElementMove = (el) => {
    this.dragging.el.removeClass('dragging');
    this.dragging = {
      el: null
    };

    this.updateElementPosition(el);
  }

  getContent = (p5) => {
    //get contentHtml
    this.htmlContent = p5.selectAll('.canvas-element', '#canvas-elements');
    this.htmlContent.forEach(el => {
        console.log('Elements', el);
        //TODO: add x and y when available in HTML
        const x = parseInt(el.attribute('data-x'));
        const y = parseInt(el.attribute('data-y'));

        //this.content.push(new ContentEl(el));
        //console.log('RELPOS' ,relPos);
        el.x = x - el.width/2;
        el.y = y - el.height/2;
        const relPos = this.getRelativePosition(el.x, el.y);
        el.relX = relPos.x;
        el.relY = relPos.y;
        el.position(el.relX, el.relY);

        //mousevents
        el.mousePressed(() => {
          this.startElementMove(el, p5)
        });
        el.mouseReleased(() => {
          this.endElementMove(el)
        })
    });
    this.setScale();
  }

  getRelativePosition = (x, y) => {
    //console.log('CENTER FOR RELPOS', this.state.center.x, this.state.center.y);
    //console.log('CANVAS', this.canvas);
    return {
      x: x - this.state.center.x + this.canvas.width/2,
      y: y - this.state.center.y + this.canvas.height/2
    }
  }

  keyReleased = (e) => {
    console.log('Key released', e);
    switch (e.keyCode){
        case 37:
            this.move('left')
            return
        case 38:
          this.move('up')
            return
        case 39:
          this.move('right')
            return
        case 40:
          this.move('down');
            return
        default: 
            return
    }
  }

  mouseClicked = (p5) => {
    console.log('clicked');
      if (p5.mouseY > 0 && p5.mouseY < p5.height && p5.mouseX > 0 && p5.mouseX < p5.width){
          let [x, y] = [Math.floor(p5.mouseX), Math.floor(p5.mouseY)];
          //let center = this.getRelativePosition(x, y)
          //this.state.dragging.el = 'world';
          this.dispatch({type: DATA_ACTIONS.SET_LAST_CLICKED, lastClicked:{x,y}})
      }
  }

  move = (direction) =>{
    console.log(`canvas should move ${direction}`);
    let xDiff = 0;
        let yDiff = 0;
        if(direction == 'up') {
            yDiff = MOVE_SCALE;
        } else if (direction == 'down') {
            yDiff = -MOVE_SCALE;
        } else if (direction == 'left') {
            xDiff = MOVE_SCALE;
        } else if (direction == 'right') {
            xDiff = -MOVE_SCALE
        }
        this.state.center.x += xDiff;
        this.state.center.y += yDiff;

        this.htmlContent.forEach(el => {
            el.relX += xDiff;
            el.relY += yDiff;
        });

        this.drawContent();
  }

  moveCenterTo = (x, y) => {
    console.log('New center', x, y);
    this.state.center = {
      x,
      y
    }

    //reposition all content elements;
    this.htmlContent.forEach(el => {
      console.log('New input', el.x, el.y);
      const relPos = this.getRelativePosition(el.x, el.y);
      console.log('New position', relPos);
      el.relX = relPos.x;
      el.relY = relPos.y;
    });

    //redraw
    this.drawContent();
  }

  setScale = () => {
    this.htmlContent.forEach(el => {
      el.style('transform', `scale(1)`);
    })
  }

  startElementMove = (el, p5) => {
    this.dragging.el = el;
    this.dragging.offsetX = el.x - p5.mouseX;
    this.dragging.offsetY = el.y - p5.mouseY;
    this.dragging.onsetX = el.width + this.dragging.offsetX;
    this.dragging.onsetY = el.height + this.dragging.offsetY;

    this.dragging.el.addClass('dragging');

    //test for using clicked props
    this.state.clicked(el);
  }

  render() {
    return <Sketch  setup={this.setup} 
                    draw={this.draw}
                    mouseClicked={this.mouseClicked} 
                    keyReleased={this.keyReleased}
                    />;
  }
}


const mapStateToProps = function(state) {
  return {
    moverAction: state.data.moverAction,
    gotoCanvasPosition: state.data.gotoCanvasPosition
  }
}

export default connect(mapStateToProps)(Map);