import React, {useEffect, useState} from 'react';
import { useSelector} from "react-redux";
import {FETCH_STATES} from "../services/client"

const SCQuery = ({query, children, single})=>{
    const [data, setData] = useState(null);
    const [oldQuery, setOldQuery] = useState(query);
    const [fetchState, setFetchState] = useState(FETCH_STATES.idle);
    const storedata = useSelector(state => state.data);
    
    useEffect(() => {
        if (storedata.client){
            if (data == null && fetchState === FETCH_STATES.idle){
                setFetchState(FETCH_STATES.BUSY);
                storedata.client.fetch(query)
                .then((newdata)=>{
                    setFetchState(FETCH_STATES.idle);
                    setData(newdata);
                }).catch(error=>{ 
                    if(storedata.client && storedata.client.environment.dev){
                        console.log(error)
                    }
                    console.log('allo')
                    setFetchState(FETCH_STATES.error)});    
            }

            if(query !== oldQuery){
                setOldQuery(query);
                setData(null);
            }
        }
    }, [storedata.client, data, fetchState, query, oldQuery]);
    if (fetchState === FETCH_STATES.BUSY) return null;
    if (fetchState === FETCH_STATES.ERROR)  return null;
    if (!data || (!single && (!data.length || !data[0]))) return null;
    if ((!data || (!single && (!data.length || !data[0])))&&(storedata.client && storedata.environment.dev)){
        console.log(data); console.log(query)
    }
    return (children(data))
}


export default SCQuery;