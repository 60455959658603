import React, {useState} from 'react';
import styled from 'styled-components';
import Button from './Button'
import {Section} from '../theme'
import {SendIcon, CloseIcon, MoreIcon, IconContainer} from '../theme/icons'
import toast from '../services/toast'
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const MenuContainer = styled.div`
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 10;
    transform: ${props=>props.transform?props.transform:null};
`

const ContextualMenu = ({actions, transform})=>{
    const [open, setOpen] = useState(false);
    const toggle = ()=>{setOpen(!open)};

    return (
        <Section style={{position: "relative"}}>
            <MenuContainer transform={transform}>
                {open ?
                    <Section style={{width: "200px"}}>
                        {Object.entries(actions).map(([key, value], index)=>{
                            if (typeof value=='object' && value.copy){
                                return <CopyToClipboard text={value.value} key={index}
                                            onCopy={() => toast(`${value.value} was copied to clipboard`)}
                                            >
                                    <Button text={key} onClick={()=>{}}  noBottom/>
                              </CopyToClipboard>
                            } else {
                                return <Button text={key} onClick={value} key={index} noBottom/>
                            }
                        })}
                        <Button text="close" onClick={toggle} />
                    </Section>
                    :
                    <IconContainer onClick={toggle}>
                        <MoreIcon style={{color: "blue"}} />
                    </IconContainer>
                }
            </MenuContainer>
        </Section>
    )
}

export default ContextualMenu;