import React, {useEffect, useState} from 'react';
import { NavLink } from 'react-router-dom';
import {MenuItem} from './Nav'
import { toast } from 'react-toastify';
import {Section, Text} from '../theme'
import { useSelector, useDispatch } from "react-redux";
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import {fonts, colors} from "../theme/config.json";
import styled from 'styled-components';
import Button from './Button'
import { useCookies } from 'react-cookie';


const MenuTitle = styled.a`
  font-size: ${fonts.size.listItem};
  text-align: ${props => props.align ||  fonts.align.listItem};
  font-weight: ${fonts.weight.listItem};
  text-transform: ${fonts.transform.listItem};
  color: ${colors.stek_blue}
`

const Input = styled.input`
width: 100%;
border: solid black 2px;
margin: 10px;
padding: 5px;
margin-bottom: 0px;
box-sizing: border-box;
`
const Col2 = styled.td`
width: 100%;
`
const Col3 = styled.td`
width: 40px;
`

const Table = styled.table`
width: 100%;
`

const Container = styled.table`
width: 100%;
`

const Memberlogin = ()=>{
const [expand, setExpand] = useState(false);
const [name, setName] = useState('');
const [pass, setPass] = useState('');
const store = useSelector(state => state.data);
const dispatch = useDispatch();
const [cookie, setCookie, removeCookie] = useCookies(['stek']);
const [blockAutomatic, setBlockAutomatic] = useState(false);


useEffect(() => {
    if (!store.member && !blockAutomatic && store.client && cookie.name && cookie.password){
        login(cookie.name, cookie.password);
    }
}, [cookie, store.member, store.client, setCookie, removeCookie]);

const login =(name, pass)=>{
    store.client.fetch('*[name == "'+name+'" && password=="'+pass+'"][0]{_id, name, "avatar":avatar.asset->url, bio, whatsapp, "slug":slug.current}')
    .then((data)=>{
        if (data && data.name && data.name == name){
            toast("Welcome to the stek pack " + data.name.replace(/ .*/,''), {hideProgressBar: true})
            setCookie('name', data.name);
            setCookie('password', pass);
            dispatch({type: DATA_ACTIONS.SET_MEMBER, member: data})
        }
    })
}

const logout =()=>{
    setBlockAutomatic(true);
    removeCookie('name');
    removeCookie('password');
    toast("Logging out " + store.member.name, {hideProgressBar: true})
    setTimeout(() => {
        dispatch({type: DATA_ACTIONS.SET_MEMBER, member: null})            
        setExpand(false);
    }, 1000);
}

if (store && store.member){
    return  <Section>
        <MenuTitle onClick={logout} style={{cursor: "pointer"}}>
        Logout 
        </MenuTitle>
    </Section> 
} else if (expand){
    return <Container> 
        <MenuTitle>
        Login 
        </MenuTitle>
        <br/>
        <Table>
            <tr>
                <td>
                    <Text>
                        Name
                    </Text>
                </td>
                <Col2>
                    <Input type="text" name="name" value={name} onChange = {e => setName(e.target.value)} />
                </Col2>
                <Col3> &nbsp;&nbsp; </Col3>
            </tr>
            <tr>
                <td>
                    <Text>
                        Pass
                    </Text>
                </td>
                <Col2>
                    <Input type="text" name="pass" value={pass} onChange = {e => setPass(e.target.value)} />
                </Col2>
                <Col3> &nbsp;&nbsp; </Col3>
            </tr>
        </Table>
        <br/>
        <Button text="send" onClick={()=>{login(name, pass)}} />
    </Container>
} else {
    return <MenuItem onClick={()=>{setExpand(true)}}> Login </MenuItem>
}
}


  export default Memberlogin;

