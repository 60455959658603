import React, {useState, useEffect} from 'react';
import Expand from 'react-expand-animated';
import {Section, Wrapper, Container, ContainerPadding} from '../theme'
import Button from '../components/Button';



const CollapsibleContainer = ({children, beginOpen, title, noPadding, onChange, noWrapper, noContainer})=>{
    const [open, setOpen] = useState(beginOpen);
    
    useEffect(() => {
        if (onChange != undefined){
            onChange(open);
        }
    });

    const getContent = ()=>{
        const innerc = (noPadding? children: <ContainerPadding >{children}</ContainerPadding>)
        if (noWrapper){
            return innerc;
        } else {
            return <Wrapper noTop>{innerc}</Wrapper>
        }
    }

    if (noContainer){
        return <Section>
            <Button text={title} altfont={true} onClick={()=>{setOpen(!open)}} options={{bold:true}} style={{fontFamily: "VGA"}} />
            <Expand open={open} >
                {getContent()}
            </Expand>
        </Section>
    } else {
        return <Container>
        <Button text={title} onClick={()=>{setOpen(!open)}} options={{bold:true}} altfont={true} />
        <Expand open={open} >
            {getContent()}
        </Expand>
    </Container>
    }
    
    

}

export default CollapsibleContainer;