import React, {useRef, useEffect, useState} from 'react';
import Button from '../components/Button'
import {ContainerNoSides} from '../theme'
import YouTube from 'react-youtube';

export const youtubeParser =(url)=>{
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return (match&&match[7].length==11)? match[7] : false;
}


const YoutubeList = ({links})=>{
    const ref = useRef(null);      
    const [width, setWidth] = useState(null);
    
    useEffect(() => {
        if (ref.current && width !== ref.current.offsetWidth ){
            setWidth(ref.current.offsetWidth)
        }

        const handleResize = ()=>{
            setWidth(null)
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
      }, [ref.current, width, setWidth]);

    return(
        <ContainerNoSides ref={ref} >
            {links.map((link, index)=>
            <div style={{width: '100% !important'}}>
                {width?<YouTube videoId={youtubeParser(link.url)} opts={{width: width}} />:null}
            </div>
            )}
        </ContainerNoSides>)
}
export default YoutubeList;
